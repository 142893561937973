import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useState } from 'react';
import UserPool from '../UserPool';
import '../components/Style.css';

function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyProcess, setVerifyProcess] = useState(false);
  const [OTP, setOTP] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      })
    );
    UserPool.signUp(username, password, attributeList, null, (err, data) => {
      if (err) {
        console.log(err);
        alert("Couldn't sign up");
      } else {
        console.log(data);
        setVerifyProcess(true);
        alert('User Added Successfully, Check your Email for verification code');
      }
    });
  };

  const verifyAccount = (e) => {
    e.preventDefault();
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    console.log(user);
    user.confirmRegistration(OTP, true, (err, data) => {
      if (err) {
        console.log(err);
        alert("Couldn't verify account");
      } else {
        console.log(data);
        alert('Account verified successfully');
        window.location.href = '/login';
      }
    });
  };

  return (
    <div>
      {verifyProcess === false ? (
        <form onSubmit={onSubmit}>
          <img src={require('../images/InsTIL_Logo.webp')} alt="IMG" width={151} height={55} />
          <div className='mb-3'>
            <input
              type="text" className="form-control" placeholder="User Name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            /></div>

          <div className='mb-3'>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            /></div>

          <div className='mb-3'>
            <input
              type="password" className="form-control" placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            /></div>

          <br />
          <div className="d-grid">
            <button type="submit" className="btn btn-success">Sign Up</button>
          </div>

          <p className="forgot-password text-right">
            Already registered <a href="/login">sign in?</a>
          </p>

        </form>
      ) : (
        <form onSubmit={verifyAccount}>
          Enter the OTP:
          <input
            type="text"
            value={OTP}
            onChange={(e) => setOTP(e.target.value)}
          />
          <br />
          <button type="submit" className="btn btn-success">Verify</button>
        </form>
      )}
    </div>
  );
}

export default Register;
