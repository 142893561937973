import axios from "axios";
import ReactPaginate from "react-paginate";

import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Spinner, Button, Accordion, Form } from 'react-bootstrap';
import { FaEdit, FaUserClock, FaTasks, FaChartLine, FaChartBar, FaBook, FaFileUpload, FaFileDownload } from "react-icons/fa";
import { MdColorLens, MdCallEnd } from 'react-icons/md';
import { GrDocumentSound, GrMail, GrAnnounce } from 'react-icons/gr';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import Switch from "react-switch";
import { baseURL, headers } from "../constants";

import '../Styles/Paginate.css';
import '../Styles/custom.css';
import '../Styles/Accordion.css';

const Company = () => {
    const [data, setData] = React.useState(null);
    const compName = useRef(null);
    const compAddr = useRef(null);
    const compMail = useRef(null);
    const compDeskNo = useRef(null);
    const compURL = useRef(null);
    const [Loaded, setLoaded] = useState(false);
    const [updated, setUpdated] = useState(false);

    function updatePost() {
        data.CompanyName = compName.current.value;
        data.CompanyAddress = compAddr.current.value;
        data.CompanyExternalUrl = compURL.current.value;
        data.SDPhoneNo = compDeskNo.current.value;
        data.CompanyMailid = compMail.current.value;
        //console.log(compName.current.value);
        
        axios.post(baseURL+'/Admin/Company/Save', data, { headers }).then((response) => {
            //setData(response.data);
            setUpdated(true);
            alert ("Record updated successfully");
        });
    }

    useEffect(() => {
        if (data == null) {
            axios.get(baseURL + "/Admin/Company/GetCompany").then((response) => {
                setData(response.data);
                setLoaded(true);
                //console.log("Company Details:",response.data);
            })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    return (
        <>
            {!updated ? <Spinner animation="border" variant="success" className="float-center" /> : null}
            {!Loaded ? <Spinner animation="border" variant="success" className="float-center" /> : (
                <div className="container fluid" >
                    <div className="card" style={{ padding: "20px" }}>
                        <Row style={{ padding: "20px" }}>
                            <Col>
                                <label style={{ position: 'absolute', left: '40px' }}>Company Name</label>
                            </Col>
                            <Col>
                                <input style={{ position: 'absolute', left: '200px', maxWidth: '400px' }}
                                    type="text" ref={compName} className="form-control border-0" placeholder="Company Name"
                                    defaultValue={data.CompanyName}
                                />
                            </Col>
                        </Row>
                        <Row style={{ padding: "20px" }}>
                            <Col>
                                <label style={{ position: 'absolute', left: '40px' }}>Address</label>
                            </Col>
                            <Col>
                                <input style={{ position: 'absolute', left: '200px', maxWidth: '400px' }}
                                    type="text" ref={compAddr} className="form-control border-0" placeholder="Company Address"
                                    defaultValue={data.CompanyAddress}
                                />
                            </Col>
                        </Row >
                        <Row style={{ padding: "20px" }}>
                            <Col>
                                <label style={{ position: 'absolute', left: '40px' }}>Email ID</label>
                            </Col>
                            <Col>
                                <input style={{ position: 'absolute', left: '200px', maxWidth: '400px' }}
                                    type="text" ref={compMail} className="form-control border-0" placeholder="Mail ID"
                                    defaultValue={data.CompanyMailid}
                                />
                            </Col>
                        </Row>
                        <Row style={{ padding: "20px" }}>
                            <Col>
                                <label style={{ position: 'absolute', left: '40px' }}>Company URL</label>
                            </Col><Col>
                                <input style={{ position: 'absolute', left: '200px', maxWidth: '400px', borderBottom: '1', borderColor: 'red' }}
                                    type="text" ref={compURL} className="form-control border-0" placeholder="Company URL"
                                    defaultValue={data.CompanyExternalUrl}
                                />
                            </Col>
                        </Row>
                        <Row style={{ padding: "20px" }}>
                            <Col>
                                <label style={{ position: 'absolute', left: '40px' }}>Service Desk No.</label>
                            </Col><Col>
                                <input style={{ position: 'absolute', left: '200px', maxWidth: '400px' }}
                                    type="text" ref={compDeskNo} className="form-control border-0" placeholder="Service Desk Phone No"
                                    defaultValue={data.SDPhoneNo}
                                />
                            </Col>
                        </Row>
                        <Row style={{ padding: "20px" }}>
                            <Col>
                                <label style={{ position: 'absolute', left: '40px' }}>Logo [Size : 200x70]</label>
                            </Col>
                            <Col>
                                <button type="submit" className="btn btn-success" style={{ width: "100px" }}>Browse</button>
                            </Col>
                        </Row>

                        <div className="d-grid gap-4 d-md-flex">
                            <div className="">
                                <button type="submit" className="btn btn-success" onClick={updatePost}>Update</button>
                            </div>
                            <div className="">
                                <button type="reset" className="btn btn-secondary">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
const GeoLocation = () => {
    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}>+ Add</Button>
                    <hr></hr>
                    <table className="table .table-bordered">
                        <thead>
                            <tr>
                                <th>Location ID</th>
                                <th>Status</th>
                                <th>Location</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Country</th>
                                <th>Block</th>
                                <th>Floor</th>
                                <th>Bay</th>
                                <th>Company Name</th>
                            </tr>
                        </thead>
                        <tbody >
                            {currentItems.map((item) => {
                                return (
                                    <tr key={item.locationid}>
                                        <td>{item.locationid}</td>
                                        <td>{item.LStatus}</td>
                                        <td>{item.LLocation}</td>
                                        <td>{item.LCity}</td>
                                        <td>{item.LState}</td>
                                        <td>{item.LCountry}</td>
                                        <td>{item.LBlock}</td>
                                        <td>{item.LFloor}</td>
                                        <td>{item.LBay}</td>
                                        <td>{item.LCompanyName}</td>
                                        <td >
                                            <button className="edit-btn" onClick={() => onEdit({ id: item.locationid })}>
                                                <FaEdit />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        if (items.length === 0) {
            axios.get(baseURL + '/Admin/GeoLocation/GetGeolist')
                .then(function (res) {
                    setItems(res.data);
                    setCurrentItems(res.data.slice(itemOffset, endOffset));
                    setpageCount(Math.ceil(res.data.length / 10));
                    //console.log(currentItems);
                    Items({ currentItems });
                    setLoaded(true);
                    //console.log("Length:- ",items.length)
                });
        }
    }, []);


    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        //console.log("Selected ",data.selected,newOffset);
        setCurrentItems(items.slice(newOffset, endOffset));
        //setItems(currentItems);
        //console.log(newOffset, endOffset, itemsPerPage);
        Items({ currentItems });
    };

    const onEdit = ({ id }) => {
        console.log("ID " + id);
    }

    return (

        <div>
            {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
            <Items currentItems={currentItems} />
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-end pagiColor'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}

            />
        </div>
    )
}
const Vendors = () => {
    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [itemCount, setItemCount] = useState(false);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}>+ New</Button>
                    <hr></hr>
                    <table className="table .table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Status</th>
                                <th>Vendor Code</th>
                                <th>Vendor Name</th>
                                <th>City</th>
                                <th>Vendor Type</th>
                                <th>Mobild No.</th>
                                <th>Email Id</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody >
                            {itemCount > 0 ? currentItems.map((item) => {
                                return (
                                    <tr key={item.vendorid}>
                                        <td>{item.vendorstatus}</td>
                                        <td>{item.vendor_code}</td>
                                        <td>{item.vendor_name}</td>
                                        <td>{item.city}</td>
                                        <td>{item.vendor_type}</td>
                                        <td>{item.vmobileno}</td>
                                        <td>{item.vemailid}</td>
                                        <td>{item.vaddress}</td>
                                        <td >
                                            <Button className="edit-btn" onClick={() => onEdit({ id: item.vendorid })}>
                                                <FaEdit />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }) : (
                                <p>No items available</p>
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        if (items.length === 0) {
            axios.get('https://demoapi.instilonline.com/Admin/GetVendorlist')
                .then(function (res) {
                    setItems(res.data);
                    setCurrentItems(res.data.slice(itemOffset, endOffset));
                    setpageCount(Math.ceil(res.data.length / 10));
                    setItemCount(res.data.length);
                    Items({ currentItems });
                    setLoaded(true);
                });
        }
    }, []);


    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(items.slice(newOffset, endOffset));
        Items({ currentItems });
    };

    const onEdit = ({ id }) => {
        console.log("ID " + id);
    }

    return (
        <div>
            {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
            <Items currentItems={currentItems} />
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-end pagiColor'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    )
}
const Contract = () => {
    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [itemCount, setItemCount] = useState(false);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}>+ New</Button>
                    <hr></hr>
                    <table className="table .table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Contract ID</th>
                                <th>Contract Name</th>
                                <th>Vendor Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Contract Value</th>
                            </tr>
                        </thead>
                        <tbody >
                            {itemCount > 0 ? currentItems.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.contractname}</td>
                                        <td>{item.vendorname}</td>
                                        <td>{item.startdate}</td>
                                        <td>{item.enddate}</td>
                                        <td>{item.contractvalue}</td>
                                        <td >
                                            <Button className="edit-btn" onClick={() => onEdit({ id: item.id })}>
                                                <FaEdit />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }) : (
                                <p>No items available</p>
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        if (items.length === 0) {
            axios.get('https://demoapi.instilonline.com/Admin/GetContractList')
                .then(function (res) {
                    setItems(res.data);
                    setCurrentItems(res.data.slice(itemOffset, endOffset));
                    setpageCount(Math.ceil(res.data.length / 10));
                    setItemCount(res.data.length);
                    Items({ currentItems });
                    setLoaded(true);
                });
        }
    }, []);


    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(items.slice(newOffset, endOffset));
        Items({ currentItems });
    };

    const onEdit = ({ id }) => {
        console.log("ID " + id);
    }

    return (
        <div>
            {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
            <Items currentItems={currentItems} />
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-end pagiColor'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    )
}
const UserClassification = () => {
    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [itemCount, setItemCount] = useState(false);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}>+ New</Button>
                    <hr></hr>
                    <table className="table .table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>User Classification</th>
                                <th>Group Color</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>

                        {itemCount > 0 ? currentItems.map((item) => {
                            return (
                                <tbody >
                                    <tr key={item.UserClassificationid}>
                                        <td>{item.UserClassification}</td>
                                        <td>{item.GroupColor}</td>
                                        <td>{item.Remarks}</td>
                                        <td >
                                            <button className="edit-btn" onClick={() => onEdit({ id: item.id })}>
                                                <FaEdit />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            )
                        }) : (
                            <tbody><tr><td>No items available</td></tr></tbody>
                        )
                        }

                    </table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        if (items.length === 0) {
            axios.get('https://demoapi.instilonline.com/Admin/GetUserClassificationlist')
                .then(function (res) {
                    setItems(res.data);
                    setCurrentItems(res.data.slice(itemOffset, endOffset));
                    setpageCount(Math.ceil(res.data.length / 10));
                    setItemCount(res.data.length);
                    Items({ currentItems });
                    setLoaded(true);
                });
        }
    }, []);


    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(items.slice(newOffset, endOffset));
        Items({ currentItems });
    };

    const onEdit = ({ id }) => {
        console.log("ID " + id);
    }

    return (
        <div>
            {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
            <Items currentItems={currentItems} />
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-end pagiColor'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    )
}
const DepartmentMaster = () => {
    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [itemCount, setItemCount] = useState(false);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}>+ New</Button>
                    <hr></hr>
                    <table className="table .table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Department Name</th>
                                <th>LOB</th>
                                <th>SBU</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody >
                            {itemCount > 0 ? currentItems.map((item) => {
                                return (
                                    <tr key={item.dept_id}>
                                        <td>{item.dept_id}</td>
                                        <td>{item.DepartmentName}</td>
                                        <td>{item.LOB}</td>
                                        <td>{item.SBU}</td>
                                        <td>{item.Status}</td>
                                        <td >
                                            <button className="edit-btn" onClick={() => onEdit({ id: item.dept_id })}>
                                                <FaEdit />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }) : (
                                <p>No items available</p>
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        if (items.length === 0) {
            axios.get(baseURL+'/Admin/Department/GetDepartmentMasterList')
                .then(function (res) {
                    setItems(res.data);
                    setCurrentItems(res.data.slice(itemOffset, endOffset));
                    setpageCount(Math.ceil(res.data.length / 10));
                    setItemCount(res.data.length);
                    Items({ currentItems });
                    setLoaded(true);
                });
        }
    }, []);


    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(items.slice(newOffset, endOffset));
        Items({ currentItems });
    };

    const onEdit = ({ id }) => {
        console.log("ID " + id);
    }

    return (
        <div>
            {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
            <Items currentItems={currentItems} />
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-end pagiColor'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    )
}
const MailSettings = () => {
    const [data, setData] = React.useState(null);
    const outServer = useRef(null);
    const outPort = useRef(null);
    const outUsername = useRef(null);
    const outSdemailid = useRef(null);
    const outPassword = useRef(null);
    const outEmailsignature = useRef(null);
    const inMailserver = useRef(null);
    const inPort = useRef(null);
    const inEmailid = useRef(null);
    const inPassword = useRef(null);
    const inEmailsubject = useRef(null);
    const inDelimitedstring = useRef(null);

    const [Loaded, setLoaded] = useState(false);

    function updatePost() {
        const headers = {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
        };

        data.outserver = outServer.current.value;
        data.outport = outPort.current.value;
        data.outusername = outUsername.current.value;
        data.outsdemailid = outSdemailid.current.value;
        data.outpassword = outPassword.current.value;
        data.outemailsignature = outEmailsignature.current.value;
        data.inmailserver = inMailserver.current.value;
        data.inport = inPort.current.value;
        data.inemailid = inEmailid.current.value;
        data.inpassword = inPassword.current.value;
        data.inemailsubject = inEmailsubject.current.value;
        data.indelimitedstring = inDelimitedstring.current.value;
        //console.log(data);
        //console.log(compName.current.value);

        axios.post(baseURL, data, { headers }).then((response) => {
            setData(response.data);
        });
    }

    useEffect(() => {
        if (data == null) {
            axios.get(baseURL + "/Admin/GetMailServerSetting").then((response) => {
                setData(response.data);
                //console.log(response);
                setLoaded(true);
            })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    return (
        <>
            {!Loaded ? <Spinner animation="border" variant="success" className="float-center" /> : (
                <div>
                    <Row>
                        <div className="container-flex">
                            <div className="infobox">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>OUTGOING
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Col md={8}>
                                                <Row className="common-row">
                                                    <Col>
                                                        <label>Server Name / IP Address *</label>
                                                    </Col>
                                                    <Col>
                                                        <input ref={outServer}
                                                            className="inp-ul"
                                                            defaultValue={data.outserver}></input>
                                                    </Col>
                                                </Row>
                                                <Row className="common-row">
                                                    <Col>
                                                        <label>Port*</label>
                                                    </Col>
                                                    <Col>
                                                        <input ref={outPort}
                                                            className="inp-ul" defaultValue={data.outport}></input>
                                                    </Col>
                                                </Row>
                                                <Row className="common-row">
                                                    <Col>
                                                        <label>Sender Name*</label>
                                                    </Col>
                                                    <Col>
                                                        <input ref={outUsername}
                                                            className="inp-ul" defaultValue={data.outusername}></input>
                                                    </Col>
                                                </Row>
                                                <Row className="common-row">
                                                    <Col>
                                                        <label>Email*</label>
                                                    </Col>
                                                    <Col>
                                                        <input ref={outSdemailid}
                                                            className="inp-ul" defaultValue={data.outsdemailid}></input>
                                                    </Col>
                                                </Row>
                                                <Row className="common-row">
                                                    <Col>
                                                        <label>Password</label>
                                                    </Col>
                                                    <Col>
                                                        <input ref={outPassword}
                                                            className="inp-ul" type="password" defaultValue=""></input>
                                                    </Col>
                                                </Row>
                                                <Row className="common-row">
                                                    <label>Email Signature</label>
                                                    <input ref={outEmailsignature}
                                                        type="textarea">
                                                    </input>
                                                </Row>
                                            </Col>
                                            <Col md={8}>
                                                <Row className="common-row">
                                                    <Col>
                                                        <label>Protocol *</label>
                                                    </Col>
                                                    <Col>
                                                        <input type="radio" value="SMTP" name="protocol" /> SMTP
                                                        <input type="radio" value="SMTPS" name="protocol" /> SMTPS
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Accordion.Body >
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>INCOMING
                                        </Accordion.Header>
                                        <Accordion.Body >
                                            <div>
                                                <Col md={4}>
                                                    <Row className="common-row">
                                                        <Col>
                                                            <label>Mail Server</label>
                                                        </Col>
                                                        <Col>
                                                            <input ref={inMailserver}
                                                                className="inp-ul" defaultValue={data.inmailserver}></input>
                                                        </Col>
                                                    </Row>
                                                    <Row className="common-row">
                                                        <Col>
                                                            <label>Port*</label>
                                                        </Col>
                                                        <Col>
                                                            <input ref={inPort}
                                                                className="inp-ul" defaultValue={data.inport}></input>
                                                        </Col>
                                                    </Row>
                                                    <Row className="common-row">
                                                        <Col>
                                                            <label>Email*</label>
                                                        </Col>
                                                        <Col>
                                                            <input ref={inEmailid}
                                                                className="inp-ul" defaultValue={data.inemailid}></input>
                                                        </Col>
                                                    </Row>
                                                    <Row className="common-row">
                                                        <Col>
                                                            <label>Password</label>
                                                        </Col>
                                                        <Col>
                                                            <input ref={inPassword}
                                                                className="inp-ul" type="password" defaultValue=""></input>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6}>
                                                    <Row className="common-row">
                                                        <Col>
                                                            <label>Protocol *</label>
                                                        </Col>
                                                        <Col>
                                                            <input type="radio" value="SMTP" name="protocol" /> SMTP
                                                            <input type="radio" value="SMTPS" name="protocol" /> SMTPS
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </Accordion.Body >
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>DELIMITER
                                        </Accordion.Header>
                                        <Accordion.Body >
                                            <Row className="common-row">
                                                <Col>
                                                    <label>Email Subject</label>
                                                </Col>
                                                <Col>
                                                    <input ref={inEmailsubject}
                                                        className="inp-ul" defaultValue={data.inemailsubject}></input>
                                                </Col>
                                            </Row>
                                            <Row className="common-row">
                                                <Col>
                                                    <label>Delimiter String</label>
                                                </Col>
                                                <Col>
                                                    <input ref={inDelimitedstring}
                                                        className="inp-ul" defaultValue={data.indelimitedstring}></input>
                                                </Col>
                                            </Row>
                                            <Row className="common-row">
                                                <Col>
                                                    <Button variant="secondary">
                                                        TEST MAIL
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="secondary">
                                                        CLOSE
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Accordion.Body >
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </Row>
                </div>
            )}
        </>
    );
}
const Utilities = () => {
    const [data, setData] = React.useState(null);
    const attendance = useRef(null);
    const theme = useRef(null);
    const autocallclose = useRef(null);
    const task = useRef(null);
    const announcement = useRef(null);
    const knowlendge = useRef(null);
    const skillmatrix = useRef(null);
    const csat = useRef(null);
    const emailclearance = useRef(null);

    const [Loaded, setLoaded] = useState(true);
    const [attendanceChecked, setAttendanceChecked] = useState(false);
    const [themeChecked, setThemeChecked] = useState(false);
    const [autoCallCloseChecked, setAutoCallCloseChecked] = useState(false);
    const [taskChecked, setTaskChecked] = useState(false);
    const [announcementChecked, setAnnouncementChecked] = useState(false);
    const [knowledgeChecked, setKnowledgeChecked] = useState(false);
    const [skillMatrixChecked, setSkillMatrixChecked] = useState(false);
    const [csatChecked, setCSATChecked] = useState(false);
    const [emailClearanceChecked, setEmailClearanceChecked] = useState(false);

    function handleAttendanceChange() {
        setAttendanceChecked(!attendanceChecked);
    };
    function handleThemeChange() {
        setThemeChecked(!themeChecked);
    };
    function handleAutoCallCloseChange() {
        setAutoCallCloseChecked(!autoCallCloseChecked);
    };
    function handleTaskChange() {
        setTaskChecked(!taskChecked);
    };
    function handleAnnouncementChange() {
        setAnnouncementChecked(!announcementChecked);
    };
    function handleKnowledgeChange() {
        setKnowledgeChecked(!knowledgeChecked);
    };
    function handleSkillMatrixChange() {
        setSkillMatrixChecked(!skillMatrixChecked);
    };
    function handleCSATChange() {
        setCSATChecked(!csatChecked);
    };
    function handleEmailClearanceChange() {
        setEmailClearanceChecked(!emailClearanceChecked);
    };
    function updatePost() {
        const headers = {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
        };

        /*data.outserver = outServer.current.value;
        data.outport = outPort.current.value;
        data.outusername = outUsername.current.value;
        data.outsdemailid = outSdemailid.current.value;*/
        //console.log(data);
        //console.log(compName.current.value);

        axios.post(baseURL, data, { headers }).then((response) => {
            setData(response.data);
        });
    }

    useEffect(() => {
        /*if (data == null) {
            axios.get(baseURL + "Admin/GeSetting").then((response) => {
                setData(response.data);
                //console.log(response);
                setLoaded(true);
            })
                .catch((error) => {
                    console.log(error);
                });
        }*/
    }, []);

    return (
        <>
            {!Loaded ? <Spinner animation="border" variant="success" className="float-center" /> : (
                <div>
                    <Row>
                        <div>
                            <div className="infobox">
                                <Row>
                                    <Col md={3} className="col-card">
                                        <div className="horizontal-card" >
                                            <i style={{ backgroundColor: "#40E0D0", color: "white", padding: "5px" }}> <FaUserClock size={70} /> </i>
                                            <Switch
                                                onChange={handleAttendanceChange}
                                                checked={attendanceChecked}
                                                className="react-switch"
                                            />
                                            <span style={{ marginTop: "20px", padding: "5px" }}> ATTENDANCE</span>

                                        </div>
                                    </Col>
                                    <Col md={3} className="col-card">
                                        <div className="horizontal-card" >
                                            <i style={{ backgroundColor: "orange", color: "white", padding: "5px" }}> <MdColorLens size={70} /> </i>
                                            <Switch
                                                onChange={handleThemeChange}
                                                checked={themeChecked}
                                                className="react-switch"
                                            />
                                            <span style={{ marginTop: "20px", padding: "5px" }}> THEME</span>
                                        </div>
                                    </Col>
                                    <Col md={3} className="col-card">
                                        <div className="horizontal-card" >
                                            <i style={{ backgroundColor: "#9966ff", color: "white", padding: "5px" }}> <MdCallEnd size={70} /> </i>
                                            <Switch
                                                onChange={handleAutoCallCloseChange}
                                                checked={autoCallCloseChecked}
                                                className="react-switch"
                                            />
                                            <span style={{ marginTop: "20px", padding: "5px" }}>AUTO CALL CLOSE</span>
                                        </div>
                                    </Col>
                                    <Col md={3} className="col-card">
                                        <div className="horizontal-card" >
                                            <i style={{ backgroundColor: "#6495ED", color: "white", padding: "5px" }}> <FaTasks size={70} /> </i>
                                            <Switch
                                                onChange={handleTaskChange}
                                                checked={taskChecked}
                                                className="react-switch"
                                            />
                                            <span style={{ marginTop: "20px", padding: "5px" }}>TASK</span>
                                        </div>
                                    </Col>
                                    <Col md={3} className="col-card">
                                        <div className="horizontal-card" >
                                            <i style={{ backgroundColor: "#607D8B", color: "white", padding: "5px" }}> <HiOutlineSpeakerphone size={70} /> </i>
                                            <Switch
                                                onChange={handleAnnouncementChange}
                                                checked={announcementChecked}
                                                className="react-switch"
                                            />
                                            <span style={{ marginTop: "20px", padding: "5px" }}>ANNOUNCEMENT</span>
                                        </div>
                                    </Col>
                                    <Col md={3} className="col-card">
                                        <div className="horizontal-card" >
                                            <i style={{ backgroundColor: "#EC7063", color: "white", padding: "5px" }}> <FaBook size={70} /> </i>
                                            <Switch
                                                onChange={handleKnowledgeChange}
                                                checked={knowledgeChecked}
                                                className="react-switch"
                                            />
                                            <span style={{ marginTop: "20px", padding: "5px" }}>KNOWLEDGE</span>
                                        </div>
                                    </Col>
                                    <Col md={3} className="col-card">
                                        <div className="horizontal-card" >
                                            <i style={{ backgroundColor: "#00cc99", color: "white", padding: "5px" }}> <FaChartLine size={70} /> </i>
                                            <Switch
                                                onChange={handleSkillMatrixChange}
                                                checked={skillMatrixChecked}
                                                className="react-switch"
                                            />
                                            <span style={{ marginTop: "20px", padding: "5px" }}>SKILL MATRIX</span>
                                        </div>
                                    </Col>
                                    <Col md={3} className="col-card">
                                        <div className="horizontal-card" >
                                            <i style={{ backgroundColor: "#FF00FF", color: "white", padding: "5px" }}> <FaChartBar size={70} /> </i>
                                            <Switch
                                                onChange={handleCSATChange}
                                                checked={csatChecked}
                                                className="react-switch"
                                            />
                                            <span style={{ marginTop: "20px", padding: "5px" }}>CSAT</span>
                                        </div>
                                    </Col>
                                    <Col md={3} className="col-card">
                                        <div className="horizontal-card" >
                                            <i style={{ backgroundColor: "#009999", color: "white", padding: "5px" }}> <GrMail size={70} /> </i>
                                            <Switch
                                                onChange={handleEmailClearanceChange}
                                                checked={emailClearanceChecked}
                                                className="react-switch"
                                            />
                                            <span style={{ marginTop: "20px", padding: "5px" }}>EMAIL CLEARANCE</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="common-row">
                                    <div class="col border-end  d-flex justify-content-center align-items-center">
                                        <Col>
                                            <Button variant="secondary" style={{ width: "100px" }}>SAVE</Button>
                                            <Button variant="secondary" style={{ width: "100px", margin: "10px" }}>CANCEL</Button>
                                        </Col>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Row>
                </div>
            )}
        </>
    );
}
const License = () => {

    return (
        <>
            <div>
                <div style={{ boxShadow: '1px 2px 15px gray', margin: '2em', padding: '1em', width: "300px" }}>
                    <h5> SUBSCRIPTION </h5>
                    <hr />
                    <Button variant="secondary" style={{ height: "80px", width: "120px", fontSize: "20px", margin: "10px" }}>Request</Button>
                    <Button variant="secondary" style={{ height: "80px", width: "120px", fontSize: "20px" }}>Enroll</Button>
                </div>
            </div>
        </>
    )
}
const SLA = () => {
    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [itemCount, setItemCount] = useState(false);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}>+ New</Button>
                    <hr></hr>
                    <table className="table .table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>SLA Type</th>
                                <th>Response Time</th>
                                <th>Resolution Time</th>
                                <th>L1 User Name</th>
                                <th>L1 Emaiil</th>
                                <th>L1 Mobile No</th>
                                <th>L2 User Name</th>
                                <th>L2 Emaiil</th>
                                <th>L2 Mobile No</th>
                                <th>L3 User Name</th>
                                <th>L3 Emaiil</th>
                                <th>L3 Mobile No</th>
                            </tr>
                        </thead>
                        <tbody >
                            {itemCount > 0 ? currentItems.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td >
                                            <Button className="edit-btn" onClick={() => onEdit({ id: item.id })}>
                                                <FaEdit />
                                            </Button>
                                        </td>
                                        <td>{item.slatype}</td>
                                        <td>{item.responsetime}</td>
                                        <td>{item.resolutiontime}</td>
                                        <td>{item.l1username}</td>
                                        <td>{item.l1emailid}</td>
                                        <td>{item.l1mobileno}</td>
                                        <td>{item.l2username}</td>
                                        <td>{item.l2emailid}</td>
                                        <td>{item.l2mobileno}</td>
                                        <td>{item.l3username}</td>
                                        <td>{item.l3emailid}</td>
                                        <td>{item.l3mobileno}</td>
                                    </tr>
                                )
                            }) : (
                                <tr><td>No items available</td></tr>
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        if (items.length === 0) {
            axios.get('https://demoapi.instilonline.com/Admin/SLAList')
                .then(function (res) {
                    setItems(res.data);
                    setCurrentItems(res.data.slice(itemOffset, endOffset));
                    setpageCount(Math.ceil(res.data.length / 10));
                    setItemCount(res.data.length);
                    Items({ currentItems });
                    setLoaded(true);
                });
        }
    }, []);


    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(items.slice(newOffset, endOffset));
        Items({ currentItems });
    };

    const onEdit = ({ id }) => {
        console.log("ID " + id);
    }

    return (
        <div>
            {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
            <Items currentItems={currentItems} />
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-end pagiColor'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    )
}
const CSVupload = () => {
    
    const [showButtons, setShowButtons] = useState(false);

    function handleOptionChange(e) {
        console.log("Selected Option: "+e);
        e==="0" ? setShowButtons(false) : setShowButtons(true);
    }
    function handleChange(e) {
        console.log(e);
    }

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleDownloadClick = event => {
        console.log(event.value)
    };

    return (
        <div style={{padding:"10px"}}>
            <div className='d-flex'>
                <label style={{ color: "gray", width: "120px", marginLeft:"10px" }}>Upload Type</label>
                <select name="CSV-Type" id="CSV-Type" className='option-my' onChange={(event) => handleOptionChange(event.target.value)}>
                    <option value="0">--Select--</option>
                    <option value="1">Department</option>
                    <option value="2">GeoLocation</option>
                    <option value="3">Users</option>
                    <option value="4">UsersClassification</option>
                    <option value="5">Contracts</option>
                    <option value="6">AssetCodeGeneration</option>
                    <option value="7">Vendors</option>
                    <option value="8">SoftwareGroup</option>
                    <option value="9">SLA</option>
                </select>
            </div>
            {showButtons ?
            <div className='d-flex' id='updown-btn'>
                <Button onClick={handleClick} className="icon-btn">
                    <FaFileUpload size={30}/> Upload
                </Button>
                <input type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />
                {' '}
                <Button onClick={handleDownloadClick} className="icon-btn">
                    <FaFileDownload size={30} /> Download
                </Button>
            </div>
            : null }
        </div>
    )
}

export { Company, GeoLocation, Vendors, Contract, MailSettings, UserClassification, DepartmentMaster, Utilities, License, SLA, CSVupload }