import React, { useState, useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import '../Styles/custom.css';
//import { IoOptions } from "react-icons/io5";
import { baseURL, headers } from "../constants";
//import { UserId } from "../pages/GetData";

const partialRegex = /^(-?\d)+(\.|,)?$/;
const partialRegex2 = /^(-?\d)+((\.\d+)?|(,\d+)?)((e|E)-?)?$/;
const fullRegex = /^(-?\d)+((\.\d+)?|(,\d+)?)((e|E)-?\d+)?$/;

let userInfoApi = baseURL + "/GetAllUserInfo"
let locationApi = baseURL + "/GetAllLocation"
let GerUserById = baseURL + "/GerUserById"
let complaintsAPI = baseURL + "/GetComplaintMappingList"
//let callLogSaveAPI = baseURL + '/CallLogSave'


const requestUsersInfo = axios.get(userInfoApi, headers);
const requestLocations = axios.get(locationApi, headers);
const requestComplaintsCategory = axios.get(complaintsAPI, headers);
//const requestGerUserById = axios.post(GerUserById, {"uid": JSON.parse(localStorage.getItem('users')).user_id}, headers);

const InNew = () => {

    const [data, setData] = React.useState(null);
    const [Loaded, setLoaded] = useState(false);
    const [usersInfo, setUsersInfo] = useState([]);
    //const [depts, setDepts] = useState([]);
    const [postData, setPost] = useState({
        CallType:"Incident",
        CallStatus:"New"
    });
    const [locations, setLocations] = useState([]);

    const [userId, setUserId] = React.useState(null);
    const [userName, setUserName] = React.useState(null);
    //const [loginName, setLoginName] = React.useState(null);
    const [dept, setDept] = React.useState(null);
    const [mobileNo, setMobileNo] = React.useState(null);
    const [phone, setPhone] = React.useState(null);
    const [eMail, setEmail] = React.useState(null);
    const [location, setLocation] = React.useState(null);
    //const callCategory = useRef(null);
    const [complaintCategory, setComplaintCategory] = React.useState([]);
    const [complaints, setComplaints] = React.useState([]);
    const [complaintDetails, setComplaintDetails] = React.useState([]);
    const [complaintCategorys, setComplaintCategorys] = React.useState([]);
    const [compOptions, setCompOptions] = React.useState(null);
    const [compDetOptions, setCompDetOptions] = React.useState(null);
    const [compCatOptions, setCompCatOptions] = React.useState(null);
    var cCategory = [];
    var cComplaints = [];
    var cComplaintDetails = [];
    var cComplaintCategorys = [];

    function userChange(event) {
        setUserId(event);
        let dt = usersInfo.filter((user) => user.User_id === String(event))[0].Department;
        setDept(dt ? dt : "");
        setMobileNo(usersInfo.filter((user) => user.User_id === String(event))[0].MobileNo);
        setPhone(usersInfo.filter((user) => user.User_id === String(event))[0].DeskNumber);
        setEmail(usersInfo.filter((user) => user.User_id === String(event))[0].Emailid);
        setLocation(usersInfo.filter((user) => user.User_id === String(event))[0].Location);   
        //console.log("Department:",dt);
    }

    function unique(obj) {
        var uniques = [];
        var stringify = {};
        for (var i = 0; i < obj.length; i++) {
            var keys = Object.keys(obj[i]);
            keys.sort(function (a, b) { return a - b });
            var str = '';
            for (var j = 0; j < keys.length; j++) {
                str += JSON.stringify(keys[j]);
                str += JSON.stringify(obj[i][keys[j]]);
            }
            if (!stringify.hasOwnProperty(str)) {
                uniques.push(obj[i]);
                stringify[str] = true;
            }
        }
        return uniques;
    }

    function handleCallSubmit() {
        
        postData.LoginUserid = JSON.parse(localStorage.getItem('users')).user_id;
        postData.Roles = "User";
        postData.Username = userName;
        postData.Department = dept;
        postData.Emailid = eMail;
        postData.MobileNo = mobileNo;
        postData.PhoneNo = phone;
        
        //console.log("Json:",JSON.parse(postData));
        axios.post(baseURL+'/CalllogSave', postData, { headers }).then((response) => {
            console.log(response.data.CallLogID);
        });        
        //console.log("post: ",postData);
    }

    useEffect(() => {
        axios.all([requestUsersInfo, requestLocations, requestComplaintsCategory]).then(axios.spread((...responses) => {
            setUsersInfo(responses[0].data);
            setLocations(responses[1].data);
            setData(responses[2].data);
            userChange(JSON.parse(localStorage.getItem('users')).user_id);
            //console.log("Uid:",JSON.parse(localStorage.getItem('users')).user_id);
        })).catch(error => {
            console.log("Error:",error);
        }).finally(() => {
            setLoaded(true);
        })
        //console.log(users[0]);
    }, []);

    useEffect(() => {
        if (data) {
            const cCategory = unique(data.map(({ CompCallCategoryid, CompCallCategory }) => ({ CompCallCategoryid, CompCallCategory })));
            const cComplaints = unique(data.map(({ CompCallCategoryid, CompCallComplaintid, CompCallComplaint }) => ({ CompCallCategoryid, CompCallComplaintid, CompCallComplaint })));
            const cComplaintDetails = unique(data.map(({ CompCallComplaintid, CompCallComplaintDetailid, CompCallComplaintDetail }) => ({ CompCallComplaintid, CompCallComplaintDetailid, CompCallComplaintDetail })));
            const cComplaintCategorys = unique(data.map(({ CompCallComplaintDetailid, CompCallComplaintCategoryid, CompCallComplaintCategory }) => ({ CompCallComplaintDetailid, CompCallComplaintCategoryid, CompCallComplaintCategory })));
            setComplaintCategory(cCategory);
            setComplaints(cComplaints);
            setComplaintDetails(cComplaintDetails);
            setComplaintCategorys(cComplaintCategorys);
            setLoaded(true);
            userChange(JSON.parse(localStorage.getItem('users')).user_id);
        }
    }, [data]);

    return (
        <div style={{ margin: "20px" }}>
            <Row>
                <h5>Incident New</h5>
            </Row>
            {!Loaded ? <Spinner animation="border" variant="success" className="float-center" /> : (
                <div className="body-box">
                    <Row className='flex-box'>
                        <div className='subHead'>
                            USER INFO
                        </div>
                        <Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        User Name
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Select aria-label="Select User" defaultValue={userId}
                                            onChange={e => {                                        
                                                setDept(usersInfo.filter((user) => user.User_id === e.target.value)[0].Department);
                                                setMobileNo(usersInfo.filter((user) => user.User_id === e.target.value)[0].MobileNo);
                                                setPhone(usersInfo.filter((user) => user.User_id === e.target.value)[0].DeskNumber);
                                                setEmail(usersInfo.filter((user) => user.User_id === e.target.value)[0].Emailid);
                                                setLocation(usersInfo.filter((user) => user.User_id === e.target.value)[0].Location);
                                                setUserName(usersInfo.filter((user) => user.User_id === e.target.value)[0].FirstName);
                                                //postData.Username = userName;   
                                                postData.Userid = e.target.value;
                                            }}
                                        >
                                            {usersInfo.map((userInfo) => (
                                                <option key={userInfo.User_id} value={userInfo.User_id} label={userInfo.FirstName}>{userInfo.FirstName}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Form.Label column sm="2">
                                        Department
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control type="select" value={dept} readOnly />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Mobile No
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control type="number" placeholder={mobileNo} 
                                        maxLength="10"
                                        
                                        onChange={e => {setMobileNo(e.target.value)
                                        if (e.target.value.length>10) {alert("Input 10 digit mobile number")}
                                        //console.log(e.target.value.length);
                                        }} />
                                    </Col>
                                    <Form.Label column sm="2">
                                        Phone No
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control type="number" placeholder={phone} 
                                        onChange={e => setPhone(e.target.value)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Email ID
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control type="email" value={eMail} />
                                    </Col>
                                    <Form.Label column sm="2">
                                        Location
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Select aria-label="Location Select" value={location}
                                            onChange={e => {
                                                setLocation(e.target.value);
                                                //console.log("Location: ", e.target.value);
                                            }}
                                        >
                                            {locations.map((location) => (
                                                <option key={location.Value} value={location.Value}>{location.Text}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Row>
                    <Row className='flex-box'>
                        <div className='subHead'>
                            CALL LOGGING
                        </div>
                        <Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Call Category
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Select aria-label="Category Select"
                                            onChange={e => {
                                                //console.log("Event: ", e.target.value);                                
                                                setCompOptions(complaints.filter(sComp => sComp.CompCallCategoryid === e.target.value).map((comp) => (
                                                    <option key={comp.CompCallComplaintid} value={comp.CompCallComplaintid}>{comp.CompCallComplaint}</option>
                                                )));
                                                setCompDetOptions(null);
                                                setCompCatOptions(null);
                                                //CallCategoryid
                                                postData.CallCategoryid = e.target.value;
                                            }}
                                        >
                                            <option>Select Call category</option>
                                            {complaintCategory.map((cate) => (
                                                <option key={cate.CompCallCategoryid} value={cate.CompCallCategoryid}>{cate.CompCallCategory}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    {complaints ?
                                        <>
                                            <Form.Label column sm="2" style={{ width: "190px" }}>
                                                Complaint
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Select aria-label="Compaint Select" placeholder={"Select Complaint"}
                                                    onChange={e => {
                                                        setCompDetOptions(complaintDetails.filter(sCompDet => sCompDet.CompCallComplaintid === e.target.value).map((compDet) => (
                                                            <option key={compDet.CompCallComplaintDetailid} value={compDet.CompCallComplaintDetailid}>{compDet.CompCallComplaintDetail}</option>
                                                        )));
                                                        //CallComplaintid
                                                        postData.CallComplaintid = e.target.value;
                                                    }}
                                                >
                                                    <option>Select Compaint</option>
                                                    {compOptions}
                                                </Form.Select>
                                            </Col>
                                        </> : null
                                    }
                                    <Form.Group as={Row} className="mb-3" style={{ paddingTop: "10px" }}>
                                        {compDetOptions ?
                                            <>
                                                <Form.Label column sm="2">
                                                    Complaint Details
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Select aria-label="Compaint Details Select"
                                                        onChange={e => {
                                                            setCompCatOptions(complaintCategorys.filter(sCompCat => sCompCat.CompCallComplaintDetailid === e.target.value).map((compCat) => (
                                                                <option key={compCat.CompCallComplaintCategoryid} value={compCat.CompCallComplaintCategoryid}>{compCat.CompCallComplaintCategory}</option>
                                                            )));
                                                            //CallComplaintDetailid
                                                            postData.CallComplaintDetailid = e.target.value;
                                                        }}
                                                        defaultValue="--Select Complaint Details--"
                                                    >
                                                        {compDetOptions}
                                                    </Form.Select>
                                                </Col>
                                            </>
                                            : null
                                        }
                                        {compCatOptions ?
                                            <>
                                                <Form.Label column sm="2" style={{ width: "200px" }}>
                                                    Complaint Category
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Select aria-label="Compaint Category Select"
                                                        onChange={e => {
                                                            //CallComplaintCategoryid
                                                            postData.CallComplaintCategoryid = e.target.value;
                                                        }}
                                                        defaultValue="--Select Complaint Category--"
                                                    >
                                                        {compCatOptions}
                                                    </Form.Select>
                                                </Col>
                                            </> : null}
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="2">
                                            Asset
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control type="text" defaultValue="--Select Asset--" />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Description
                                    </Form.Label>
                                    <Col sm="8">
                                        <Form.Control as="textarea" placeholder="Description" rows={3} 
                                        onChange={e =>{
                                            postData.ProblemDescription = e.target.value;
                                        }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Attachment
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control type="file" 
                                        onChange={e=>{
                                            console.log(e.target.value);
                                        }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group style={{ padding: "10px" }}>
                                    <Button variant="secondary" className="btn-my"
                                        onClick={handleCallSubmit}
                                    >SAVE</Button>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Row>
                </div>
            )}
        </div>
    )
}

const InStatus = () => {

    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}
                    >+ Add</Button>
                    <hr></hr>
                    <table className="table .table-bordered">
                        <thead>
                            <tr>
                                <th>Call ID</th>
                                <th>Description</th>
                                <th>Call Category</th>
                                <th>Call log Date Time</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody >
                            {currentItems.map((item) => {
                                return (
                                    <tr key={item.CallLogID}>
                                        <td>{item.CallLogID}</td>
                                        <td>{item.ProblemDescription}</td>
                                        <td>{item.CallCategory}</td>
                                        <td>{item.CreatedOn}</td>
                                        <td>{item.CallStatus}</td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        axios.post(baseURL + "/GetCallLoglist", { "id": JSON.parse(localStorage.getItem('users')).user_id }, headers)
            .then(function (res) {
                setItems(res.data);
                setCurrentItems(res.data.slice(itemOffset, endOffset));
                setpageCount(Math.ceil(res.data.length / 10));
                //console.log(currentItems);
                Items({ currentItems });
                setLoaded(true);
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                setLoaded(true)
            })
    }, []);

    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(items.slice(newOffset, endOffset));
        Items({ currentItems });
    };

    return (
        <div style={{ margin: "20px" }}>
            <div className="body-box">
                <Row>
                    <h5>Call Log Status</h5>
                </Row>
                <div>
                    {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
                    <Items currentItems={currentItems} />
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-end pagiColor'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </div>
    )
}

const InApproval = () => {

    return (
        <>
            <div>
                <h5>INCIDENT APPROVAL</h5>
            </div>
        </>
    )
}

const ReqNew = () => {

    const [data, setData] = React.useState(null);
    const [Loaded, setLoaded] = useState(false);
    const [usersInfo, setUsersInfo] = useState([]);
    const [userId, setUserId] = React.useState(null);
    const [userName, setUserName] = React.useState(null);
    const [loginName, setLoginName] = React.useState(null);
    const [dept, setDept] = React.useState(null);
    const [mobileNo, setMobileNo] = React.useState(null);
    const [phone, setPhone] = React.useState(null);
    const [eMail, setEmail] = React.useState(null);
    const [location, setLocation] = React.useState(null);
    const [users, setUsers] = useState([]);
    const [depts, setDepts] = useState([]);
    const [callCategorys, setCallCategorys] = useState([]);
    const [locations, setLocations] = useState([]);

    const deptName = useRef(null);

    const phoneNo = useRef(null);
    const emailId = useRef(null);

    const callCategory = useRef(null);

    function deptChange(event) {
        axios.post(GerUserById, { "uid": event }, headers)
            .then((response) => {
                //console.log("Dept ", response.data.Department);
                //console.log(event);
                userName.current.value = event;
                deptName.current.value = response.data.Department;
                mobileNo.current.value = response.data.MobileNo;
                phoneNo.current.value = response.data.DeskNumber;
                emailId.current.value = response.data.Emailid;
                location.current.value = response.data.Location;
            })
    }

    useEffect(() => {
        axios.all([requestUsersInfo, requestLocations, requestComplaintsCategory]).then(axios.spread((...responses) => {

            setUsersInfo(responses[0].data);
            setLocations(responses[1].data);
            setData(responses[2].data);
            //console.log("T: ",JSON.parse(localStorage.getItem('users')).user_id);
        })).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoaded(true)
        })
    }, []);

    return (
        <div style={{ margin: "20px" }}>
            {!Loaded ? <Spinner animation="border" variant="success" className="float-center" /> : (
                <div className="body-box">
                    <Row className='flex-box'>
                        <div className='subHead'>
                            USER INFO
                        </div>
                        <Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        User Name
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Select aria-label="Select User" defaultValue={userId}
                                            onChange={e => {
                                                setLoginName(usersInfo.filter((user) => user.User_id === e.target.value)[0].LoginName)
                                                setDept(usersInfo.filter((user) => user.User_id === e.target.value)[0].Department);
                                                setMobileNo(usersInfo.filter((user) => user.User_id === e.target.value)[0].MobileNo);
                                                setPhone(usersInfo.filter((user) => user.User_id === e.target.value)[0].DeskNumber);
                                                setEmail(usersInfo.filter((user) => user.User_id === e.target.value)[0].Emailid);
                                                setLocation(usersInfo.filter((user) => user.User_id === e.target.value)[0].Location);
                                                //console.log("e: ", e.target.value);
                                            }}
                                        >
                                            {usersInfo.map((userInfo) => (
                                                <option key={userInfo.User_id} value={userInfo.User_id} label={userInfo.FirstName}>{userInfo.FirstName}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Form.Label column sm="2">
                                        Department
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control type="select" placeholder="Department" value={dept} readOnly />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Mobile No
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control type="number" placeholder={mobileNo} 
                                        maxLength="10"
                                        max="99"
                                        onChange={e => {setMobileNo(e.target.value)
                                        if (e.target.value.length>10) {alert("Input 10 digit mobile number")}
                                        //console.log(e.target.value.length);
                                        }} />
                                    </Col>
                                    <Form.Label column sm="2">
                                        Phone No
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control type="number" placeholder={phone} 
                                        onChange={e => setPhone(e.target.value)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Email ID
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control type="email" value={eMail} />
                                    </Col>
                                    <Form.Label column sm="2">
                                        Location
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Select aria-label="Location Select" value={location}
                                            onChange={e => {
                                                setLocation(e.target.value);
                                                //console.log("Location: ", e.target.value);
                                            }}
                                        >
                                            {locations.map((location) => (
                                                <option key={location.Value} value={location.Value}>{location.Text}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Row>
                    <Row className='flex-box'>
                        <div className='subHead'>
                            CALL LOGGING
                        </div>
                        <Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Reqeust Name
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Select aria-label="Call Category Select" ref={callCategory} >
                                            {callCategorys.map((callCategory) => (
                                                <option value={callCategory.Value}>{callCategory.Text}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Description
                                    </Form.Label>
                                    <Col sm="8">
                                        <Form.Control as="textarea" placeholder="Description" rows={3} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="2">
                                        Attachment
                                    </Form.Label>

                                    <Col sm="6">
                                        <Form.Control type="file" />
                                    </Col>
                                </Form.Group>
                                <Form.Group style={{ padding: "10px" }}>
                                    <Button variant="secondary" className="btn-my"

                                    >SAVE</Button>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Row>
                </div>
            )}
        </div>
    )
}

const ReqStatus = () => {

    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}>+ Add</Button>
                    <hr></hr>
                    <table className="table .table-bordered">
                        <thead>
                            <tr>
                                <th>Call ID</th>
                                <th>Description</th>
                                <th>Call Category</th>
                                <th>Call log Date Time</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody >
                            {currentItems.map((item) => {
                                return (
                                    <tr key={item.CallLogID}>
                                        <td>{item.CallLogID}</td>
                                        <td>{item.ProblemDescription}</td>
                                        <td>{item.CallCategory}</td>
                                        <td>{item.CreatedOn}</td>
                                        <td>{item.CallStatus}</td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        axios.post(baseURL + "/GetRequestCallLoglist", { "id": JSON.parse(localStorage.getItem('users')).user_id }, headers)
            .then(function (res) {
                setItems(res.data);
                setCurrentItems(res.data.slice(itemOffset, endOffset));
                setpageCount(Math.ceil(res.data.length / 10));
                //console.log(currentItems);
                Items({ currentItems });
                setLoaded(true);
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                setLoaded(true)
            })
    }, []);

    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(items.slice(newOffset, endOffset));
        Items({ currentItems });
    };

    return (
        <div style={{ margin: "20px" }}>
            <div className="body-box">
                <Row>
                    <h5>Call Log Status</h5>
                </Row>
                <div>
                    {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
                    <Items currentItems={currentItems} />
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-end pagiColor'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </div>
    )
}
export { InNew, InStatus, InApproval, ReqNew, ReqStatus };