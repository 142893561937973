import React from 'react';
import axios from 'axios';

export default class GetAllLocations extends React.Component {
  state = {
    locations: []
  }

  componentDidMount() {
    axios.get('https://demoapi.instilonline.com/ShiftMaster/Getalllocation')
      .then(res => {
        const locations = res.data;
        this.setState({ locations });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        <div class="table-responsive" style={{ display: 'flex', top: '100px' }}>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Location Code</th>
                <th scope="col">Location Name</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.locations
                  .map((item, i) => (
                    <tr key={i}>
                      <td>{item.Value}</td>
                      <td>{item.Text}</td>
                    </tr>
                  ))
              }
            </tbody></table></div>
      </>
    )
  }
}
