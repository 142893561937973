import React, { useState, createContext } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { baseURL } from "../constants";
import axios from 'axios';
//import '../App.css';
import '../Styles/custom.css';

const AccountContext = createContext();

Amplify.configure({
  Auth: {
    userPoolId: 'ap-south-1_fUYMlfx0j',
    region: 'ap-south-1',
    userPoolWebClientId: '3j7q3qn1tcgmsccvstonsrndoe'
  }
});

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    Auth.signIn(username, password).then((result) => {
      console.log("Login success");
      axios.get(baseURL+"/GetUserInfo", { params: { LoginName: username }}).then((response) => {
        localStorage.setItem('users', JSON.stringify(response.data.Table[0]));
        console.log(response.data.Table[0]);
        //alert(response.data.Table[0]);
      }).finally(() =>{
        window.location.reload();
      });
    }).catch((err) => {
      alert(err)
    })
  };

  return (
    <div className="container" style={{ position: 'absolute', left: '50%', top: '40%', transform: 'translate(-50%, -50%)', maxWidth: "500px" }}>
      <div className="auth-box" >
        <form onSubmit={onSubmit}>
          <div className='mb-3'>
            <img src={require('../images/InsTIL_Logo.webp')} alt="IMG" style={{ width: "80%" }} />
          </div>
          <div className=' mb-3'>
            <input
              className="form-control form-control-lg"
              style={{}}
              type="text"
              placeholder="User Name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className='mb-3'>
            <input
              type="password"
              className="form-control form-control-lg"
              placeholder="Password"
              value={password}
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="d-grid">
            <button type="submit"
              className="btn btn-success btn-lg"
              style={{ width: "100px", fontSize: "20px", borderRadius: "10px" }}>Login</button>
          </div>

          <div className="forgot-password text-right">
            Forgot <a href="/resetPassword">Password?</a>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
