// in src/chatbot/SupportOptions.jsx
import React from "react";
import "./SupportOptions.css";

const SupportOptions = (props) => {
  const options = [
    { text: "Create new ticket", 
        handler: props.actionProvider.handleCreateTicket, 
        id: 1 },
    { text: "Check status", handler: 
        props.actionProvider.handleCheckStatus, 
        id: 2 },
    { text: "Contact technician", 
        handler: props.actionProvider.handleContactTech, 
        id: 3 },
    { text: "Contact Service desk", handler: () => {}, id: 4 },
    { text: "Update ticket", handler: () => {}, id: 5 },
  ];

  const optionsMarkup = options.map((option) => (
    <button
      className="support-option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="support-options-container">{optionsMarkup}</div>;
};

export default SupportOptions;

