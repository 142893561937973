// in src/chatbot/SupportOptions.jsx
import React from "react";
import "../SupportOptions.css";

const IssueType = (props) => {
  const options = [
    { text: "Hardware", 
        handler: props.actionProvider.handleHardware, 
        id: 1 },
    { text: "OS/Software", handler: 
        props.actionProvider.handleSoftware, 
        id: 2 },
    { text: "Network", 
        handler: props.actionProvider.handleNetwork, 
        id: 3 },
  ];

  const optionsMarkup = options.map((option) => (
    <button
      className="support-option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="support-options-container">{optionsMarkup}</div>;
};

export default IssueType;

