import axios from "axios";
import ReactPaginate from "react-paginate";
import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Spinner, Button, Accordion, Form, Table } from 'react-bootstrap';
import { FaEdit, FaUserClock, FaTasks, FaChartLine, FaChartBar, FaBook, FaFileUpload, FaFileDownload } from "react-icons/fa";
import { MdColorLens, MdCallEnd } from 'react-icons/md';
import { GrDocumentSound, GrMail, GrAnnounce } from 'react-icons/gr';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import Switch from "react-switch";

import '../Styles/Paginate.css';
import '../Styles/custom.css';
import '../Styles/Accordion.css';

const Incident = () => {
    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemCount, setItemCount] = useState(0);
    const [startItem, setStartItem] = useState(0);
    const [endItem, setEndItem] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}>+ Add</Button>
                    <hr></hr>
                    <Table striped style={{ overflow:"scroll" }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Call Category</th>
                                <th>Complaint</th>
                                <th>Complaint Detail</th>
                                <th>Complaint Category</th>
                                <th>SLA</th>
                                <th>Approval</th>
                                <th>Auto Assign</th>
                                <th>Auto Call Log</th>
                            </tr>
                        </thead>
                        <tbody >
                            {currentItems.map((item) => {
                                return (
                                    <tr key={item.CallCategoryMapping_id}>
                                        <td >
                                            <button className="edit-btn" onClick={() => onEdit({ id: item.CallCategoryMapping_id })}>
                                                <FaEdit />
                                            </button>
                                        </td>
                                        <td>{item.TxtCallcategory}</td>
                                        <td>{item.TxtComplaint}</td>
                                        <td>{item.TxtComplaintDetail}</td>
                                        <td>{item.TxtComplaintCategory}</td>
                                        <td>{item.CheckSLA1}</td>
                                        <td>{item.DirectApproval1}</td>
                                        <td>{item.DirectAssign1}</td>
                                        <td>{item.AutoCallLog1}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        if (items.length === 0) {
            axios.get('https://demoapi.instilonline.com/Incident/GetCategoryMappinglist')
                .then(function (res) {
                    setItems(res.data);
                    setCurrentItems(res.data.slice(itemOffset, endOffset));
                    setpageCount(Math.ceil(res.data.length / 10));
                    setItemCount(res.data.length);
                    setStartItem(1);
                    //console.log("Item Count: "+items.length);
                    Items({ currentItems });
                    setLoaded(true);
                    //console.log("Length:- ",items.length)
                });
        }
    }, []);


    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        //console.log("Selected ",data.selected,newOffset);
        setCurrentItems(items.slice(newOffset, endOffset));
        setStartItem(newOffset);
        setEndItem(endOffset);
        //console.log(newOffset, endOffset, itemsPerPage);
        Items({ currentItems });
    };

    const onEdit = ({ id }) => {
        console.log("ID " + id);
    }

    return (

        <div>
            {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
            <Items currentItems={currentItems} />
            <div className="d-flex">
                <div style={{ width: "300px" }}> Showing {startItem + 1} to {endItem > itemCount ? itemCount : endItem} of {itemCount} entries </div>
                <div style={{ width: "100%" }}>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-end pagiColor'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </div>
    )
}

export { Incident };