import React, { useState } from "react";
import '../Styles/custom.css';
import { Aside, TopBar } from "./Navigation";
//import Chat from '../chatbot/Chat';
//import { headers } from '../constants';
import { InNew, InStatus, InApproval, ReqNew, ReqStatus } from '../components/UserModule';


const IncidentNew = () => {

    const [showBot, setShowBot] = useState(false);

    return (
        <>      
            <div style={{ display: 'flex', height: '100%' }}>
                <Aside />
                <main style={{ width: "100%" }}>
                    <div>
                        <TopBar />
                    </div>
                    <div className="main-body">
                        <InNew />
                    </div>
                </main>
            </div>
        </>
    )
}

const IncidentStatus = () => {

    const [showBot, setShowBot] = useState(false);

    return (
        <>      
            <div style={{ display: 'flex', height: '100%' }}>
                <Aside />
                <main style={{ width: "100%" }}>
                    <div>
                        <TopBar />
                    </div>
                    <div className="main-body">
                        <InStatus />
                    </div>
                </main>
            </div>
        </>
    )
}

const IncidentApproval = () => {

    const [showBot, setShowBot] = useState(false);

    return (
        <>      
            <div style={{ display: 'flex', height: '100%' }}>
                <Aside />
                <main style={{ width: "100%" }}>
                    <div>
                        <TopBar />
                    </div>
                    <div className="main-body">
                        <InApproval />
                    </div>
                </main>
            </div>
        </>
    )
}

const RequestNew = () => {

    const [showBot, setShowBot] = useState(false);

    return (
        <>      
            <div style={{ display: 'flex', height: '100%' }}>
                <Aside />
                <main style={{ width: "100%" }}>
                    <div>
                        <TopBar />
                    </div>
                    <div className="main-body">
                        <ReqNew />
                    </div>
                </main>
            </div>
        </>
    )
}

const RequestStatus = () => {

    const [showBot, setShowBot] = useState(false);

    return (
        <>      
            <div style={{ display: 'flex', height: '100%' }}>
                <Aside />
                <main style={{ width: "100%" }}>
                    <div>
                        <TopBar />
                    </div>
                    <div className="main-body">
                        <ReqStatus />
                    </div>
                </main>
            </div>
        </>
    )
}

export {IncidentNew, IncidentStatus, IncidentApproval, RequestNew, RequestStatus };