import React, { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import axios from "axios";
import { Row, Col } from 'react-bootstrap';
import { MdOutlinePlaylistAddCheck, MdOutlineDone, MdDoneAll } from 'react-icons/md';
import { ImSpinner } from 'react-icons/im';
import Chart from "react-apexcharts";
//import { IncidentNew } from "./Incident";
import "./Styles.css";
//import { UserId } from './GetData';
import { baseURL, headers } from "../constants";
import { v4 as uuid } from 'uuid';
//import context from "react-bootstrap/esm/AccordionContext";


const UserDashboard = () => {

    const options = { labels: ["Network", "MS Office", "Laptop/Desktop", "Browser", "Software Issues", "Printer/Scanner"] };
    const series = [5, 2, 13, 6, 5, 13];

    //const [token, setToken] = useState('');
    const [uName, setUName] = useState('');
    const [isAdmin, setIsAdmin] = useState('');
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const unique_id = uuid();

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            //let jwt = accessToken.getJwtToken(); 
            //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
            //console.log(`myJwt: ${jwt}`);
            setUName(accessToken.payload.username);
            //setToken(true);
            setIsAdmin(accessToken.payload["cognito:groups"].includes('Administrators'));
        });

        if (data.length===0) {
            axios.post(baseURL + '/GetCallsCount', { "uid": JSON.parse(localStorage.getItem('users')).user_id }, { headers: headers }).then((response) => {
                setLoaded(true);
                setData(response.data);
            })
                .catch((error) => {
                    console.log("Unable to fetch GetCallsCount ", error);
                });
            }
            else {
                console.log("Have data: ",data, unique_id);
            }
    });

    return (
        <>
            <div className="container fluid">
                <Row style={{ paddingTop: "10px" }}>
                    <Col className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="block-header">
                            <h2>INCIDENT</h2>
                        </div>
                        <Row>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <Col className="info-box-4" id="box" >
                                    <div className="icon">
                                        <MdOutlinePlaylistAddCheck size={60} className="col-cyan" />
                                    </div>
                                    <div className="content">
                                        <div className="text" >TOTAL ASSIGNED</div>
                                        <div className="number" >{data.total}</div>
                                    </div>
                                </Col>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <Col className="info-box-4" id="box">
                                    <div className="icon">
                                        <ImSpinner size={60} className="col-pink" />
                                    </div>
                                    <div className="content">
                                        <div className="text">IN PROGRESS</div>
                                        <div className="number">{data.pending}</div>
                                    </div>
                                </Col>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <Col className="info-box-4" id="box">
                                    <div className="icon">
                                        <MdOutlineDone size={60} className="col-purple" />
                                    </div>
                                    <div className="content">
                                        <div className="text">COMPLETED</div>
                                        <div className="number">{data.completed}</div>
                                    </div>
                                </Col>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <Col className="info-box-4" id="box">
                                    <div className="icon">
                                        <MdDoneAll size={60} className="col-green" />
                                    </div>
                                    <div className="content">
                                        <div className="text">CLOSED</div>
                                        <div className="number">{data.closed}</div>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Col className="body" id="box" >
                                    <div className="card">
                                        <div className="header" style={{ borderBottomColor: "green", borderBottomWidth: "2px" }}>
                                            <Row> <div className="col-lg-8">
                                                <h2>INCIDENT CALL SUMMARY</h2>
                                            </div>
                                            </Row>
                                        </div>
                                        <div id="piechart">
                                            <div style={{ position: "relative" }}>
                                                <div style={{ position: "relative", width: "476px", height: "280px" }}>
                                                    <div id="chart">
                                                        <Chart options={options} series={series} type="pie" width="380" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="block-header">
                            <h2>REQUEST</h2>
                        </div>
                        <Row>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <Col className="info-box-4 " id="box" >
                                    <div className="icon">
                                        <MdOutlinePlaylistAddCheck size={60} className="col-orange" />
                                    </div>
                                    <div className="content">
                                        <div className="text" >TOTAL ASSIGNED</div>
                                        <div className="number" >44</div>
                                    </div>
                                </Col></div><div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <Col className="info-box-4" id="box">
                                    <div className="icon">
                                        <ImSpinner size={60} className="col-blue-grey" />
                                    </div>
                                    <div className="content">
                                        <div className="text">IN PROGRESS</div>
                                        <div className="number">4</div>
                                    </div>
                                </Col></div><div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <Col className="info-box-4" id="box">
                                    <div className="icon">
                                        <MdOutlineDone size={60} className="col-teal" />
                                    </div>
                                    <div className="content">
                                        <div className="text">COMPLETED</div>
                                        <div className="number">4</div>
                                    </div>
                                </Col></div><div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <Col className="info-box-4" id="box">
                                    <div className="icon">
                                        <MdDoneAll size={60} className="col-yellow" />
                                    </div>
                                    <div className="content">
                                        <div className="text">CLOSED</div>
                                        <div className="number">4</div>
                                    </div>
                                </Col></div>
                        </Row>
                        <Row>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Col className="body" id="box" >
                                    <div className="card">
                                        <div className="header" style={{ borderBottomColor: "green", borderBottomWidth: "2px" }}>
                                            <Row> <div className="col-lg-8">
                                                <h2>REQUEST CALL SUMMARY</h2>
                                            </div>
                                            </Row>
                                        </div>
                                        <div id="piechart">
                                            <div style={{ position: "relative" }}>
                                                <div style={{ position: "relative", width: "476px", height: "280px" }}>
                                                    PIE CHART HERE
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <div className="block-header">
                            <div className="col-lg-10">
                                <h2>TASK</h2>
                            </div>
                        </div>
                        <div className="card">
                            <div className="body">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>Task Name</td>
                                                <td>Description</td>
                                                <td>Status</td>
                                                <td>Start Date</td>
                                                <td>Target Date</td>
                                                <td>Days Left</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div className="block-header">
                            <div className="col-lg-10">
                                <h2>
                                    NOTIFICATION
                                </h2>
                            </div>
                        </div>
                        <div className="body">
                            <div className="card" style={{ height: "152px" }}>
                                No Notifications Found!!!
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default UserDashboard;