import React, { useState, useEffect, useRef } from "react";
import { Navbar, Dropdown, Modal, Nav, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FaHome, FaAngleLeft, FaAngleRight, FaTachometerAlt, FaClipboardList, FaUnlink, FaList, FaTasks, FaUserCircle, FaUserAlt, FaUserCog, FaSignOutAlt } from "react-icons/fa";
import { MdDevices, MdLibraryBooks } from "react-icons/md";
import { FiArrowRightCircle, FiArrowLeftCircle, FiSettings } from "react-icons/fi";
import { IoSettings } from 'react-icons/io5'
import { Auth } from 'aws-amplify';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
//import './Styles/Navigation.css';
import '../Styles/BreadCrumbs.css';
import '../Styles/custom.css';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
import { baseURL, headers } from '../constants';
import axios from "axios";

async function signOut() {
    try {
        await Auth.signOut();
        localStorage.clear();
        window.location.href = '/';
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

const TopBar = () => {
    //const { logout } = useContext(AccountContext);
    const [showProfile, setShowProfile] = useState(false);
    const [empId, setEmpId] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [department, setDepartment] = useState('');
    const [designation, setDesignation] = useState('');
    const userLocation = useRef(null);
    const [locations, setLocation] = useState([]);
    const [data, setData] = React.useState(null);
    const [isLoading, setLoading] = React.useState(true);

    async function getUserInfo() {
        const user = await Auth.currentAuthenticatedUser();
        //console.log('attributes:', user.attributes);
        await axios.get(baseURL + '/GetUserInfo?LoginName=' + user.username, headers)
            .then((response) => {
                setData(response.data.Table[0]);
                setUserName(user.username);
                setEmpId(data.Empid);
                //setUserEmail(user.attributes['email']);
                setUserEmail(data.Emailid);
                setMobileNo(data.MobileNo);
                setDepartment(data.Department);
                setDesignation(data.Designation);
                userLocation.current.value = data.Location;
                setLoading(false);
                //console.log("UserLocation",userLocation);
            })

    }
    async function getAllLocation() {
        //const user = await Auth.currentAuthenticatedUser();
        //console.log('attributes:', user.attributes);
        await axios.get(baseURL + '/Admin/ShiftMaster/Getalllocation', headers)
            .then((response) => {
                setLocation(response.data);
                //userLocation.current.value = data.Location;
                setLoading(true)
            })
    }


    const handleCloseProfile = () => {
        setShowProfile(false);
    }
    const handleShowProfile = () => {
        getAllLocation();
        getUserInfo();
        //console.log(data.Location);
        setShowProfile(true);

    }
    const handleUpdateProfile = () => {
        axios.post(baseURL + "/UpdateUserProfile", data, { headers }).then((response) => {
            if (response.data===1) alert("Record updated successfully");
            setShowProfile(false);
        });
    }

    return (
        <>
            <Navbar expand="sm" style={{ position: 'relative', background: "#1d1d1d", height: "52px" }}>
                <Container fluid style={{ paddingRight: "0" }}>
                    <Navbar.Brand href="/" style={{ color: "white" }}>
                        <FaHome />
                    </Navbar.Brand>
                    <Nav className="ms-auto">
                        <Dropdown>
                            <Dropdown.Toggle variant="none" style={{ float: 'right', background: "#1d1d1d" }} id="UserAction">
                                <FaUserAlt size={20} color="white" style={{ background: "#1d1d1d" }} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" style={{ top: "80px" }}>
                                <Dropdown.Item onClick={handleShowProfile}><FaUserCog /> User Profile</Dropdown.Item>
                                <Dropdown.Item href="/AdminDashboard"><IoSettings /> Settings</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={signOut}><FaSignOutAlt /> Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Container>
            </Navbar>

            <Modal show={showProfile} onHide={handleCloseProfile}>
                <Modal.Header closeButton>
                    <Modal.Title>User Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <div className="col-lg-12">
                                <img src={require('../images/InsTIL_Logo.webp')} alt="IMG" width="30%" style={{ float: 'right' }} />
                            </div>
                            <Col>
                                <Form.Group className="mb-3 flex" controlId="Form.ControlInput1">
                                    <Form.Label>Emp Id</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={empId}
                                        autoFocus
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="Form.ControlInput2">
                                    <Form.Label>Mobile</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={mobileNo}
                                        autoFocus
                                        onChange={e => {
                                            data.MobileNo = e.target.value;
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="Form.ControlInput3">
                                    <Form.Label>Department</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={department}
                                        autoFocus
                                        onChange={e => {
                                            data.Department = e.target.value;
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="Form.ControlInput5">
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={userName}
                                        autoFocus
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="Form.ControlInput6">
                                    <Form.Label>Designation</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={designation}
                                        autoFocus
                                        onChange={e => {
                                            data.Designation = e.target.value;
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="Form.ControlInput7">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Select aria-label="Location Select"
                                        ref={userLocation}
                                        onChange={e => {
                                            data.Location = e.target.value;
                                            //console.log("Location Change to ", e.target.value)
                                            //console.log(userLocation)
                                        }}
                                    >
                                        {locations.map((location) => (
                                            <option key={location.Value} value={location.Value}>{location.Text}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Form.Group className="mb-6" controlId="Form.ControlInput4">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder={userEmail}
                                    autoFocus
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUpdateProfile}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {

    const [uName, setUName] = useState('');
    const [empId, setEmpId] = useState('');
    const [isAdmin, setIsAdmin] = useState('');
    const [menuCollapse, setMenuCollapse] = useState(false)

    /*
    async function getUserInfo() {
        const user = await Auth.currentAuthenticatedUser();
        console.log('attributes:', user.attributes);
    }
    */
    useEffect(() => {
        const { attributes } = Auth.currentAuthenticatedUser();
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            setUName(accessToken.payload.username.toUpperCase());
            setIsAdmin(accessToken.payload["cognito:groups"].includes('Administrators'));
            //let users = localStorage.getItem(JSON.parse(localStorage.getItem('users')));
            //console.log("UserInfo : ", JSON.parse(localStorage.getItem('users')).Empid);
            setEmpId(JSON.parse(localStorage.getItem('users')).Empid);
        })
    });

    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    return (
        <>
            <ProSidebar
                image={false}
                rtl={rtl}
                collapsed={menuCollapse}
                toggled={toggled}
                breakPoint="md"
                onToggle={handleToggleSidebar}

            >
                <SidebarHeader>
                    <div
                        style={{
                            padding: "20px",
                            /*textTransform: "uppercase",*/
                            fontWeight: "bold",
                            fontSize: 14,
                            letterSpacing: "1px",
                            /*overflow: "hidden",*/
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            height: "50px"
                        }}
                    >
                        <div className="d-flex col-white">
                            {menuCollapse ? empId : ("User: " + uName)}
                            <div className="closemenu">
                                {menuCollapse ? (<FiArrowRightCircle onClick={menuIconClick} />) : <FiArrowLeftCircle onClick={menuIconClick} />}
                            </div>
                        </div>
                    </div>
                </SidebarHeader>

                <SidebarContent>

                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FaTachometerAlt color="white" />}
                            suffix={<span className="badge red">*</span>}
                        >
                            Dashboard
                            <Link to="/" />
                        </MenuItem>
                    </Menu>
                    {isAdmin ? (
                        <Menu iconShape="circle">
                            <MenuItem
                                icon={<FiSettings color="white" />}
                                suffix={<span className="badge white"></span>}
                            >
                                Administration
                                <Link to="/AdminDashboard" />
                            </MenuItem>
                        </Menu>
                    ) : null}
                    <Menu iconShape="circle">
                        <SubMenu
                            suffix={<span className="badge yellow"></span>}
                            title="Incident"
                            icon={<FaUnlink color="white" />}
                        >
                            <MenuItem>New<Link to="/Incident/New" /></MenuItem>
                            <MenuItem>Status<Link to="/Incident/Status" /></MenuItem>
                            <MenuItem>Call Approval<Link to="/Incident/Approval" /></MenuItem>

                        </SubMenu>
                        <SubMenu
                            title="Request"
                            icon={<FaClipboardList color="white" />}
                        >
                            <MenuItem>New<Link to="/Request/New" /></MenuItem>
                            <MenuItem>Status<Link to="/Request/Status" /></MenuItem>
                            <MenuItem>Call Approval</MenuItem>
                        </SubMenu>
                        <SubMenu
                            title="Asset"
                            icon={<MdDevices color="white" />}
                        >
                            <MenuItem>Asset Approval</MenuItem>
                            <MenuItem>Asset Info</MenuItem>
                            <MenuItem>Add Asset</MenuItem>
                        </SubMenu>
                        <SubMenu
                            prefix={<span className="badge gray"></span>}
                            title="Reports"
                            icon={<MdLibraryBooks color="white" />}
                        >
                            <MenuItem>Asset Report</MenuItem>
                            <MenuItem>Asset Deallocation History</MenuItem>
                            <MenuItem>Asset Transfer History</MenuItem>
                            <MenuItem>Purchase Software Report</MenuItem>
                            <MenuItem>OS Report</MenuItem>
                            <MenuItem>Call Report</MenuItem>
                            <MenuItem>Technician Wise Incident Report</MenuItem>
                            <MenuItem>Technician Wise Request Report</MenuItem>
                            <MenuItem>Attendance Report</MenuItem>
                        </SubMenu>
                        <SubMenu
                            prefix={<span className="badge gray"></span>}
                            title="Utilities"
                            icon={<FaTasks color="white" />}
                        >
                            <MenuItem>Task</MenuItem>
                            <MenuItem>Knowledge Base Approval</MenuItem>
                            <MenuItem>Skill Matrix</MenuItem>
                            <MenuItem>CSAT</MenuItem>
                            <MenuItem>SOP</MenuItem>
                        </SubMenu>
                    </Menu>
                </SidebarContent>

                <SidebarFooter style={{ textAlign: "center" }}>
                    <div
                        className="sidebar-btn-wrapper"
                        style={{
                            padding: "20px 24px"
                        }}
                    >
                        {menuCollapse ? "v1.0" : ("InsTIL v1.0")}
                    </div>
                </SidebarFooter>
            </ProSidebar>

        </>
    );
};

export { TopBar, Aside };