import Amplify, { Auth } from 'aws-amplify';
import { createContext, useState } from 'react';
import UserPool from '../UserPool';

const AccountContext = createContext();

const Account = (props) => {

    const [uName, setUName] = useState('');
    const [isAdmin, setIsAdmin] = useState('');

    async function signOut() {
        try {
            await Auth.signOut();
            window.location.href = '/';
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    }

    const getUserName = async () => {
        const { attributes } = Auth.currentAuthenticatedUser();
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            setUName(accessToken.payload.username.toUpperCase());
            setIsAdmin(accessToken.payload["cognito:groups"].includes('Administrators'));
        })
    };

    return (
        <AccountContext.Provider value={{ getUserName, signOut }}>
            {props.children}
        </AccountContext.Provider>
    );
};

export { Account, AccountContext };