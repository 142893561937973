import React from 'react';
import Chatbot from 'react-chatbot-kit'
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from './config';
import 'react-chatbot-kit/build/main.css';
import './Chat.css';

function Chat() {
  return (
    <div className="Chat">
      <header className="Chat-header">
        <Chatbot config={config} actionProvider={ActionProvider} messageParser={MessageParser} headerText='InsTIL Chatbot'/>
      </header>
    </div>
  );
}

export default Chat;