import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '../Styles/custom.css';
import { Aside, TopBar } from "./Navigation";
import UserDashboard from "./UserDashboard";
import { Modal } from "react-bootstrap";
import { MdSupportAgent, MdChat, MdKeyboardArrowDown } from 'react-icons/md'
import Chat from '../chatbot/Chat';
import { IncidentNew, IncidentStatus } from '../components/UserModule';


const Dashboard = () => {

    const [showBot, setShowBot] = useState(false);

    return (
        <>      
            <div style={{ display: 'flex', height: '100%' }}>
                <Aside />
                <main style={{ width: "100%" }}>
                    <div>
                        <TopBar />
                    </div>
                    <div className="main-body">
                        <UserDashboard />
                    </div>
                    <button className="float-bottom-right" id="Chat-Button" title="Chat" onClick={() => setShowBot(!showBot)}>
                        {!showBot ? <MdChat size={30} /> : <MdKeyboardArrowDown size={30} />}
                    </button>
                    { showBot ? <Chat /> : null}
                </main>
            </div>
        </>
    )
}

export default Dashboard;