import React, { useContext } from "react";
import { createChatBotMessage } from 'react-chatbot-kit';
import SupportOptions from "./SupportOptions";
import LinkList from "./LinkList";
import IssueType from "./widgets/TicketOptions";
import { GrRobot, GrUser } from 'react-icons/gr';
import { Account } from "../components/Account";

//const val = useContext(AccountContext);

const config = {
  botName: "ServiceDeskBot",
  initialMessages: [
    createChatBotMessage("Hi, " + Account.getUserName,
      {
        widget: "SupportOptions",
      }),
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#376B7E",
    },
  },
  customComponents: {
    botAvatar: (props) => <GrRobot size={40} {...props} />,
    userAvatar: (props) => <GrUser size={40} {...props} />
  },
  widgets: [
    {
      widgetName: "SupportOptions",
      widgetFunc: (props) => <SupportOptions {...props} />,
    },
    {
      widgetName: "createticket",
      widgetFunc: (props) => <IssueType {...props} />,
    },
    {
      widgetName: "CheckStatus",
      widgetFunc: (props) => <LinkList {...props} />,
    },
    {
      widgetName: "ContactTech",
      widgetFunc: (props) => <LinkList {...props} />,
    },
    {
      widgetName: "ContactSD",
      widgetFunc: (props) => <LinkList {...props} />,
    },
    {
      widgetName: "javascriptLinks",
      widgetFunc: (props) => <LinkList {...props} />,
      props: {
        options: [
          {
            text: "Introduction to JS",
            url:
              "https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures/basic-javascript/",
            id: 1,
          },
          {
            text: "Mozilla JS Guide",
            url:
              "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide",
            id: 2,
          },
          {
            text: "Frontend Masters",
            url: "https://frontendmasters.com",
            id: 3,
          },
        ],
      },
    },
  ],
}

export default config;