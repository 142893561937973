import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Container, Modal } from 'react-bootstrap';
import { FaBuilding, FaUsers, FaHandshake, FaUsersCog, FaSync, FaUserPlus, FaHourglassHalf, FaRegBuilding, FaUpload, FaUnlink, FaClipboardList } from 'react-icons/fa';
import { MdMail, MdLocationPin, MdNotificationsNone, MdSettings, MdDevices } from 'react-icons/md';
import { TbLicense } from 'react-icons/tb';
import { Auth } from 'aws-amplify';
import { Company, GeoLocation, Vendors, Contract, UserClassification, MailSettings, DepartmentMaster, Utilities, SLA, License, CSVupload } from './AdminSettings';
import { Incident } from './AdminModule';
//import "./Styles.css";
//import "./Styles/style.css";
import "../Styles/custom.css";

function AdminMenu() {
    const [showSettings,setShowSettings] = useState(0);
    const [showCompany, setShowCompany] = useState(false);
    const [showGeoLocation, setShowGeoLocation] = useState(false);
    const [showVendor, setShowVendor] = useState(false);
    const [showContract, setShowContract] = useState(false);
    const [showMailSettings, setShowMailSettings] = useState(false);
    const [showUserClassification, setShowUserClassification] = useState(false);
    const [showDepartment, setShowDepartment] = useState(false);
    const [showUserSync, setShowUserSync] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [showUtilities, setShowUtilities] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false);
    const [showSLA, setShowSLA] = useState(false);
    const [showLicense, setShowLicense] = useState(false);
    const [showCSVupload, setShowCSVupload] = useState(false);
    const [showIncidentSettings, setShowIncidentSettings] = useState(false);

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            //setUName(accessToken.payload.username);
            //setToken(true);
        })
    });

    return (
        <>
            <Container fluid>                
                <Row style={{ padding: "10px" }}>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>SETTINGS
                                </Accordion.Header>
                                <Accordion.Body >
                                    <div className="row clearfix" id="general">
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowCompany(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon bg-pink" style={{ minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaBuilding /></i>
                                                    </div>
                                                    <div className="content" >
                                                        <h5>Company</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowGeoLocation(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon bg-cyan" style={{ minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"> <MdLocationPin /> </i>
                                                    </div>
                                                    <div className="content">
                                                        <h5>GeoLocation</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowVendor(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon bg-orange" style={{ minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaUsers /></i>
                                                    </div>
                                                    <div className="content">
                                                        <h5>Vendors</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowContract(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon bg-grey" style={{ minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaHandshake /></i>
                                                    </div>
                                                    <div className="content">
                                                        <h5>Contracts</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowMailSettings(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon bg-purple" style={{ minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><MdMail /></i>
                                                    </div>
                                                    <div className="content">
                                                        <h5>Mail</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowUserClassification(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon bg-indigo" style={{ minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaUsersCog /></i>
                                                    </div>
                                                    <div style={{ alignItems:"center" }} >
                                                        <h5>User Classification</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowUserSync(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon" style={{ background: '#00cc99', minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaSync /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>User Sync</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowUsers(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon bg-brown" style={{ minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaUserPlus /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>Users</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowUtilities(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon bg-blue-grey" style={{ minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><MdSettings /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>Utilities</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowAlerts(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon bg-pink" style={{ minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><MdNotificationsNone /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>Alerts &amp; <br />Notifications</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowSLA(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon" style={{ background: 'mediumseagreen', minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaHourglassHalf /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>SLA</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowDepartment(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon" style={{ background: 'coral', minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaRegBuilding /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>Department</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" id="lic" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowLicense(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon" style={{ background: '#b30086', minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><TbLicense /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>License</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a id="link" onClick={() => setShowCSVupload(true)} >
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon" style={{ background: 'darksalmon', minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaUpload /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>CSV Upload</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header>MODULE
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="row clearfix" id="incident">

                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a  onClick={() => setShowIncidentSettings(true)} id="link">
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon" style={{ background: '#990099', minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaUnlink /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>Incident</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a href="/RequestCategoryMapping/RequestCategoryList" id="link">
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon" style={{ background: 'coral', minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><FaClipboardList /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>Request</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12" style={{ minWidth:"200px" }}>
                                            <a href="/MenuIconList/AssetMenu" id="link">
                                                <div className="info-box hover-zoom-effect">
                                                    <div className="icon" style={{ background: '#009999', minWidth:"50px" }}>
                                                        <i className="" aria-hidden="true"><MdDevices /></i>
                                                    </div>
                                                    <div className="content" style={{ paddingTop: '15px' }}>
                                                        <h5>Asset</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>

            <Modal
                size="lg"
                show={showCompany}
                fullscreen={false}
                onHide={() => setShowCompany(false)}
                aria-labelledby="ShowCompany-lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="ShowCompany-lg" style={{ textAlign:"center" }}>
                        Company Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><Company /></Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={showGeoLocation}
                dialogClassName="modal-90w"
                onHide={() => setShowGeoLocation(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Geo Locations
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><GeoLocation /></Modal.Body>
            </Modal>
            <Modal                
                show={showVendor}
                onHide={() => setShowVendor(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Vendor Modal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><Vendors /></Modal.Body>
            </Modal>
            <Modal
                show={showContract}
                dialogClassName="modal-90w"
                onHide={() => setShowContract(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Contract Modal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><Contract /></Modal.Body>
            </Modal>
            <Modal                
                show={showUserClassification}
                onHide={() => setShowUserClassification(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        UserClassification Modal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><UserClassification /></Modal.Body>
            </Modal>
            <Modal                
                show={showMailSettings}
                onHide={() => setShowMailSettings(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    <h5>MAIL SETTINGS</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><MailSettings /></Modal.Body>
            </Modal>
            <Modal                
                show={showDepartment}
                onHide={() => setShowDepartment(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Department Modal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><DepartmentMaster /></Modal.Body>
            </Modal>
            <Modal                
                show={showUtilities}
                onHide={() => setShowUtilities(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        UTILITIES
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><Utilities /></Modal.Body>
            </Modal>
            <Modal                
                show={showSLA}
                onHide={() => setShowSLA(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        SLA
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><SLA /></Modal.Body>
            </Modal>
            <Modal                
                show={showLicense}
                onHide={() => setShowLicense(false)}
                
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        License
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><License /></Modal.Body>
            </Modal>
            <Modal                
                show={showCSVupload}
                onHide={() => setShowCSVupload(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        CSV Bulk Upload
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><CSVupload /></Modal.Body>
            </Modal>
            <Modal                
                show={showIncidentSettings}
                onHide={() => setShowIncidentSettings(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Incident Settings
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><Incident /></Modal.Body>
            </Modal>
        </>
    );

}

export default AdminMenu;