import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Row, Col, Button, Table, Spinner, Form } from 'react-bootstrap';
import '../Styles/Paginate.css';
import '../Styles/custom.css';
import { FaEdit, FaFileUpload, FaFileDownload } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { baseURL, headers } from '../constants';
import { Auth } from 'aws-amplify';


//const baseURL = "https://demoapi.instilonline.com";

const UserId = () => {
    const user = Auth.currentAuthenticatedUser();
    let loginname = user.username;
    //let loginname = "MadhanKumar.S";
    //let loginname = localStorage.get(JSON.parse(localStorage.getItem('users')).LoginName);
    const [userId, SetUserId] = useState(null);
    const [Loaded, setLoaded] = useState(false);
    const config = {
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-headers',
        },
        params: {
            LoginName: loginname,
        },
    }

    useEffect(() => {
        if (userId === null) {
            axios.get(baseURL + "/GetUserInfo", config).then((response) => {

                //console.log(response.data.Table[0].user_id);
                //SetUserId(response.data.Table[0].user_id)
                setLoaded(true);
                localStorage.setItem('users', JSON.stringify(response.data.Table[0]));
                //localStorage.setItem('userid',response.data.Table[0].user_id)
                //console.log("EmpID : ", JSON.parse(localStorage.getItem('users')).user_id);

            })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);


    return (
        <>
            {!Loaded ? <Spinner animation="border" variant="success" className="float-center" /> : (
                null
            )}
        </>
    );
};
const SLA = () => {
    var itemsPerPage = 10;
    const [items, setItems] = useState([]);
    const [itemCount, setItemCount] = useState(false);
    const [pageCount, setpageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [Loaded, setLoaded] = useState(false);

    function Items({ currentItems }) {
        return (
            <>
                <div style={{ height: "auto" }}>
                    <hr></hr>
                    <Button variant="secondary" className="btn-my" style={{ padding: "5px", fontSize: "14px" }}>+ New</Button>
                    <hr></hr>
                    <table className="table .table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>SLA Type</th>
                                <th>Response Time</th>
                                <th>Resolution Time</th>
                                <th>L1 User Name</th>
                                <th>L1 Emaiil</th>
                                <th>L1 Mobile No</th>
                                <th>L2 User Name</th>
                                <th>L2 Emaiil</th>
                                <th>L2 Mobile No</th>
                                <th>L3 User Name</th>
                                <th>L3 Emaiil</th>
                                <th>L3 Mobile No</th>
                            </tr>
                        </thead>
                        <tbody >
                            {itemCount > 0 ? currentItems.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td >
                                            <Button className="edit-btn" onClick={() => onEdit({ id: item.id })}>
                                                <FaEdit />
                                            </Button>
                                        </td>
                                        <td>{item.slatype}</td>
                                        <td>{item.responsetime}</td>
                                        <td>{item.resolutiontime}</td>
                                        <td>{item.l1username}</td>
                                        <td>{item.l1emailid}</td>
                                        <td>{item.l1mobileno}</td>
                                        <td>{item.l2username}</td>
                                        <td>{item.l2emailid}</td>
                                        <td>{item.l2mobileno}</td>
                                        <td>{item.l3username}</td>
                                        <td>{item.l3emailid}</td>
                                        <td>{item.l3mobileno}</td>
                                    </tr>
                                )
                            }) : (
                                <tr><td>No items available</td></tr>
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        if (items.length === 0) {
            axios.get('https://demoapi.instilonline.com/Admin/SLAList')
                .then(function (res) {
                    setItems(res.data);
                    setCurrentItems(res.data.slice(itemOffset, endOffset));
                    setpageCount(Math.ceil(res.data.length / 10));
                    setItemCount(res.data.length);
                    Items({ currentItems });
                    setLoaded(true);
                });
        }
    }, []);


    const handlePageClick = (data) => {
        const newOffset = (data.selected * itemsPerPage) % items.length;
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(items.slice(newOffset, endOffset));
        Items({ currentItems });
    };

    const onEdit = ({ id }) => {
        console.log("ID " + id);
    }

    return (
        <div>
            {!Loaded && <Spinner animation="border" variant="success" className="float-center" />}
            <Items currentItems={currentItems} />
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-end pagiColor'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    )
};
const CSVupload = () => {

    const [showButtons, setShowButtons] = useState(false);

    function handleOptionChange(e) {
        console.log("Selected Option: " + e);
        e === "0" ? setShowButtons(false) : setShowButtons(true);
    }
    function handleChange(e) {
        console.log(e);
    }

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleDownloadClick = event => {
        console.log(event.value)
    };

    return (
        <div style={{ padding: "10px" }}>
            <div className='d-flex'>
                <label style={{ color: "gray", width: "120px", marginLeft: "10px" }}>Upload Type</label>
                <select name="CSV-Type" id="CSV-Type" className='option-my' onChange={(event) => handleOptionChange(event.target.value)}>
                    <option value="0">--Select--</option>
                    <option value="1">Department</option>
                    <option value="2">GeoLocation</option>
                    <option value="3">Users</option>
                    <option value="4">UsersClassification</option>
                    <option value="5">Contracts</option>
                    <option value="6">AssetCodeGeneration</option>
                    <option value="7">Vendors</option>
                    <option value="8">SoftwareGroup</option>
                    <option value="9">SLA</option>
                </select>
            </div>
            {showButtons ?
                <div className='d-flex' id='updown-btn'>
                    <Button onClick={handleClick} className="icon-btn">
                        <FaFileUpload size={30} /> Upload
                    </Button>
                    <input type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                    />
                    {' '}
                    <Button onClick={handleDownloadClick} className="icon-btn">
                        <FaFileDownload size={30} /> Download
                    </Button>
                </div>
                : null}
        </div>
    )
};
const CallsCount = () => {
    const [data, setData] = React.useState(null);
    const [userId, setUserId] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [totalIncidents, SetTotalIncidents] = useState(0);
    const headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-headers',
    }
    useEffect(() => {
        //console.log(postdata);
        axios.post(baseURL + '/GetCallsCount', { "uid": JSON.parse(localStorage.getItem('users')).user_id }, { "headers": headers }).then((response) => {
            setLoaded(true);
            setData(response.data);
            console.log("Calls Count: ", response.data);
        })
            .catch((error) => {
                console.log(error);
            });

    }, []);


    return (
        null
    )
};

const GetComplaintList = () => {
    const [data, setData] = React.useState(null);
    const [usersInfo, setUsersInfo] = React.useState([]);
    const [complaintCategory, setComplaintCategory] = React.useState([]);
    const [complaints, setComplaints] = React.useState([]);
    const [complaintDetails, setComplaintDetails] = React.useState([]);
    const [complaintCategorys, setComplaintCategorys] = React.useState([]);
    const [loaded, setLoaded] = useState(false);
    const [compOptions, setCompOptions] = React.useState(null);
    const [compDetOptions, setCompDetOptions] = React.useState(null);
    const [compCatOptions, setCompCatOptions] = React.useState(null);

    const [dept, setDept] = React.useState(null);
    const [mobileNo, setMobileNo] = React.useState(null);
    const [phone, setPhone] = React.useState(null);

    var cCategory = [];
    var cComplaints = [];
    var cComplaintDetails = [];
    var cComplaintCategorys = [];

    const headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Access-Control-Allow-Origin, Content-Type, Accept, Authorization, access-control-allow-headers',
    }

    function unique(obj) {
        var uniques = [];
        var stringify = {};
        for (var i = 0; i < obj.length; i++) {
            var keys = Object.keys(obj[i]);
            keys.sort(function (a, b) { return a - b });
            var str = '';
            for (var j = 0; j < keys.length; j++) {
                str += JSON.stringify(keys[j]);
                str += JSON.stringify(obj[i][keys[j]]);
            }
            if (!stringify.hasOwnProperty(str)) {
                uniques.push(obj[i]);
                stringify[str] = true;
            }
        }
        return uniques;
    }

    useEffect(() => {
        if (data) {
            const cCategory = unique(data.map(({ CompCallCategoryid, CompCallCategory }) => ({ CompCallCategoryid, CompCallCategory })));
            const cComplaints = unique(data.map(({ CompCallCategoryid, CompCallComplaintid, CompCallComplaint }) => ({ CompCallCategoryid, CompCallComplaintid, CompCallComplaint })));
            const cComplaintDetails = unique(data.map(({ CompCallComplaintid, CompCallComplaintDetailid, CompCallComplaintDetail }) => ({ CompCallComplaintid, CompCallComplaintDetailid, CompCallComplaintDetail })));
            const cComplaintCategorys = unique(data.map(({ CompCallComplaintDetailid, CompCallComplaintCategoryid, CompCallComplaintCategory }) => ({ CompCallComplaintDetailid, CompCallComplaintCategoryid, CompCallComplaintCategory })));
            setComplaintCategory(cCategory);
            setComplaints(cComplaints);
            setComplaintDetails(cComplaintDetails);
            setComplaintCategorys(cComplaintCategorys);
            setLoaded(true);
            //console.log("Complaint Category: ", cComplaintCategorys);
        }
    }, [data]);

    useEffect(() => {
        axios.get(baseURL + '/GetComplaintMappingList', { "headers": headers })
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        axios.get(baseURL + '/GetAllUserInfo', headers)
            .then((resp) => {
                setUsersInfo(resp.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    if (!loaded) {
        return <div className="App">Loading...</div>;
    }

    return (
        <div>
            <Row>
                <Form>
                    <Form.Group as={Row} className="mb-3">
                        <Col sm="2">
                        <Form.Label>
                            User Name
                        </Form.Label>
                        </Col>
                        <Col sm="4">
                            <Form.Select aria-label="Select User"
                                onChange={e => {
                                    setDept(usersInfo.filter((user)=>user.User_id===e.target.value)[0].Department);
                                    setMobileNo(usersInfo.filter((user)=>user.User_id===e.target.value)[0].MobileNo);
                                    setPhone(usersInfo.filter((user)=>user.User_id===e.target.value)[0].DeskNumber);
                                }}
                            >
                                {usersInfo.map((userInfo) => (
                                    <option key={userInfo.User_id} value={userInfo.User_id}>{userInfo.FirstName}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Department
                        </Form.Label>
                        <Col sm="4">
                            <Form.Control type="select" placeholder="Department" value={dept}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Mobile No
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control type="text" placeholder={mobileNo}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Phone No
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control type="text" placeholder={phone}                            
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Row>
            <Row>
                <Form>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Call Category
                        </Form.Label>
                        <Col sm="3">
                            <Form.Select aria-label="Category Select"
                                onChange={e => {
                                    //console.log("Event: ", e.target.value);                                
                                    setCompOptions(complaints.filter(sComp => sComp.CompCallCategoryid === e.target.value).map((comp) => (
                                        <option key={comp.CompCallComplaintid} value={comp.CompCallComplaintid}>{comp.CompCallComplaint}</option>
                                    )));
                                    setCompDetOptions(null);
                                    setCompCatOptions(null);
                                    //console.log(compDetOptions);
                                }}
                            >
                                {complaintCategory.map((cate) => (
                                    <option key={cate.CompCallCategoryid} value={cate.CompCallCategoryid}>{cate.CompCallCategory}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    {complaints ?
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Complaint
                            </Form.Label>
                            <Col sm="3">
                                <Form.Select aria-label="Compaint Select"
                                    onChange={e => {
                                        setCompDetOptions(complaintDetails.filter(sCompDet => sCompDet.CompCallComplaintid === e.target.value).map((compDet) => (
                                            <option key={compDet.CompCallComplaintDetailid} value={compDet.CompCallComplaintDetailid}>{compDet.CompCallComplaintDetail}</option>
                                        )));
                                    }}
                                >
                                    {compOptions}
                                </Form.Select>
                            </Col>
                        </Form.Group> : null
                    }
                    {compDetOptions ?
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Complaint Details
                            </Form.Label>
                            <Col sm="3">
                                <Form.Select aria-label="Compaint Details Select"
                                    onChange={e => {
                                        setCompCatOptions(complaintCategorys.filter(sCompCat => sCompCat.CompCallComplaintDetailid === e.target.value).map((compCat) => (
                                            <option key={compCat.CompCallComplaintCategoryid} value={compCat.CompCallComplaintCategoryid}>{compCat.CompCallComplaintCategory}</option>
                                        )));
                                    }}
                                    defaultValue="--Select Complaint Details--"
                                >
                                    {compDetOptions}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        : null
                    }
                    {compCatOptions ?
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Complaint Category
                            </Form.Label>
                            <Col sm="3">
                                <Form.Select aria-label="Compaint Category Select"
                                    onChange={e => {
                                        console.log("Event: ", e.target.value);
                                    }}
                                    defaultValue="--Select Complaint Category--"
                                >
                                    {compCatOptions}
                                </Form.Select>
                            </Col>
                        </Form.Group> : null}
                </Form>
            </Row>
        </div >
    )
};


export { UserId, CSVupload, CallsCount, GetComplaintList };