import React from "react";
//import "./style.css";
import { Aside, TopBar } from "./Navigation";
import AdminMenu from "./AdminMenu";


const Dashboard = () => {

    return (
        <>
            <div style={{ display: 'flex', height: '100%' }}>
                <Aside />
                <main style={{ width: "100%" }}>
                    <div>
                        <TopBar />
                    </div>
                    <AdminMenu />
                </main>
            </div>
        </>
    )
}

export default Dashboard;