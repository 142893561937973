//import logo from './logo.svg';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
//import './App.css';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import Login from './Auth/Login';
import Register from './Auth/Register';
import GetAllLocations from './pages/GetAllLocations.jsx';
import Dashboard from './pages/Dashboard.jsx';
import AdminDashboard from './pages/AdminDashboard.jsx';
import AdminMenu from './pages/AdminMenu.jsx';
import UserDashboard from './pages/UserDashboard';
import { CallsCount, UserId, GetComplaintList } from './pages/GetData';
import {IncidentNew, IncidentStatus, IncidentApproval, RequestNew, RequestStatus} from './pages/Incident';

function App() {
  const [token, setToken] = useState('');

  useEffect(() => {
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()
      setToken(true);
      //console.log(token);
    })
    .catch((err) => {
      console.log('Error: ', err);
    });
  });

  if(!token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/*' element={<Login />} />
        </Routes>
      </BrowserRouter>
    );
  }
  return (
    <div className="App">
        <BrowserRouter>
              <Routes>
                <Route path="/User" element={<UserDashboard />} />
                <Route path="/register" element={<Register />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/AdminDashboard" element={<AdminDashboard />} />
                <Route path="/AdminMenu" element={<AdminMenu />} />
                <Route path="/company/GetAllLocations" element={<GetAllLocations />} />   
                <Route path='/Incident/New' element={<IncidentNew />} />
                <Route path='/Incident/Status' element={<IncidentStatus />} />
                <Route path='/Incident/Approval' element={<IncidentApproval />} />
                <Route path='/Request/New' element={<RequestNew />} />
                <Route path='/Request/Status' element={<RequestStatus />} />
                <Route path='/userid' element={<UserId />} />
                <Route path='/CallsCount' element={<CallsCount />} />
                <Route path='/GetComplaintList' element={<GetComplaintList />} />
              </Routes>
        </BrowserRouter>
    </div>
  );
}


export default App;
